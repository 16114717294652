import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-view-truck-inventory',
  templateUrl: './view-truck-inventory.component.html',
  styleUrls: ['./view-truck-inventory.component.css']
})
export class ViewTruckInventoryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
