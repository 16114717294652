import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard-content',
  templateUrl: './dashboard-content.component.html',
  styleUrls: ['./dashboard-content.component.css']
})
export class DashboardContentComponent implements OnInit {

    success: string;
    error: string;

  constructor() {
      this.success = 'Success message here';
      this.error = 'Error!!';
  }

  ngOnInit() {
  }

}
