import { Component, OnInit } from '@angular/core';
import { Material, InventoryManagementService } from "process-smart-ng6-lib";
import { Router, ActivatedRoute } from "@angular/router";
import { SharedService } from "src/app/_services/shared.service";

@Component({
  selector: 'app-form-materials',
  templateUrl: './form-materials.component.html',
  styleUrls: ['./form-materials.component.css']
})
export class FormMaterialsComponent implements OnInit {
    model: Material;
    deptId: string;
    fillerUid: string;
    materialId: string;

    constructor(private ims: InventoryManagementService, private route: ActivatedRoute,
            private router: Router, private ssv: SharedService) { }
    
    ngOnInit() {
        this.fillerUid = JSON.parse(localStorage.getItem('currentUser')).user.uid;
        
        //Load or create new form
        this.route.params.subscribe(params => {
            this.deptId = params['deptId'];
            this.materialId = params['id'];
            
            if(this.materialId){
                //Load Material detail
                this.getModel();
            }
            else {
                //Create a new form
                this.model = <Material>{
                    department_id: this.deptId,
                    package_qty: '0',
                    package_unit: 'ea'
                };
            }
        });
    }
    
    onSubmit() {
      //Update DB if exists, or create a new one otherwise
        if(this.materialId){
            this.ims.updateMaterial(this.model, this.deptId, this.materialId)
            .subscribe(
            data => {
                this.ssv.showSuccess('Material successfully updated!');
                  this.router.navigate(['/form-materials/' + this.deptId + '/' +this.materialId]);
              },
              error => {
                  // Display error message
                  this.ssv.showError('Unable to add new material!');
                  console.log(error);
              }
        );
        }
        else{
            this.ims.newMaterial(this.model, this.deptId)
                .subscribe(
                data => {
                      // Page redirect when getting response
                    this.ssv.showSuccess('Material successfully added!');
                      this.router.navigate(['/view-materials/' + this.deptId]);
                  },
                  error => {
                      // Display error message
                      this.ssv.showError('Unable to add new material!');
                      console.log(error);
                  }
            );
        }
    }
    
    getModel(){
        this.ims.getMaterial(this.deptId, this.materialId)
        .subscribe(model => this.model = model);
    }
    
    get diagnostic() { return JSON.stringify(this.model); }
}
