import { Component, OnInit } from '@angular/core';
import { Container, InventoryManagementService, User, AuthenticationService } from "process-smart-ng6-lib";
import { ActivatedRoute } from "@angular/router";
import { SharedService } from "src/app/_services/shared.service";
import { Router } from "@angular/router";

@Component({
  selector: 'app-form-containers',
  templateUrl: './form-containers.component.html',
  styleUrls: ['./form-containers.component.css']
})
export class FormContainersComponent implements OnInit {
    model: Container;
    deptId: string;
    fillerUid: string;
    truckId: string;
    usersList: Array<User>;
    
    constructor(private ims: InventoryManagementService, private route: ActivatedRoute,
            private router: Router, private ssv: SharedService, private adms: AuthenticationService) { }
    
    ngOnInit() {
        this.fillerUid = JSON.parse(localStorage.getItem('currentUser')).user.uid;
        
        //Load or create new form
        this.route.params.subscribe(params => {
            this.deptId = params['deptId'];
            this.truckId = params['id'];
            
            if(this.truckId){
                //Load Truck detail
                this.getModel();
            }
            else {
                //Create a new form
                this.model = <Container>{
                    department_id: this.deptId,
                    supervisor_id: '0'
                };
            }
        });
        
        this.populateNames();
    }
    
    populateNames() {
        this.adms.getUsers()
          .subscribe(usersList => {
              if(this.deptId == '1'){
                  usersList = usersList.filter(x => x.role_id == '4' || x.role_id == '6');
              }
              else if(this.deptId == '6'){
                  usersList = usersList.filter(x => x.role_id == '13');
              }
              this.usersList = usersList;
          });
      }
    
    onSubmit() {
      //Update DB if exists, or create a new one otherwise
        if(this.truckId){
            this.ims.updateContainer(this.model, this.deptId, this.truckId)
            .subscribe(
            data => {
                this.ssv.showSuccess('Truck successfully updated!');
                  this.router.navigate(['/form-containers/' + this.deptId + '/' +this.truckId]);
              },
              error => {
                  // Display error message
                  this.ssv.showError('Unable to add new Truck!');
                  console.log(error);
              }
        );
        }
        else{
            this.ims.newContainer(this.model, this.deptId)
                .subscribe(
                data => {
                      // Page redirect when getting response
                    this.ssv.showSuccess('Truck successfully added!');
                      this.router.navigate(['/view-containers/' + this.deptId]);
                  },
                  error => {
                      // Display error message
                      this.ssv.showError('Unable to add new Truck!');
                      console.log(error);
                  }
            );
        }
    }
    
    getModel(){
        this.ims.getContainer(this.deptId, this.truckId)
        .subscribe(model => this.model = model);
    }
    
    get diagnostic() { return JSON.stringify(this.model); }
}
