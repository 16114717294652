import { Component, OnInit } from '@angular/core';
import { User, Role, AuthenticationService, DocumentConversionService } from "process-smart-ng6-lib";
import { SharedService } from "src/app/_services/shared.service";
import { Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-view-employees',
  templateUrl: './view-employees.component.html',
  styleUrls: ['./view-employees.component.css']
})
export class ViewEmployeesComponent implements OnInit {
    itemsList: Array<User>;
    partialItemsList: Array<User>;
    rolesList: Array<Role>;
    search_name = "";
    role_id = '0';
    is_active = '1';

    constructor(private adms: AuthenticationService, private ssv: SharedService, 
            private router: Router, private route: ActivatedRoute,
            private dcs: DocumentConversionService) { }
    
    ngOnInit() {
        //Authentication
        if(JSON.parse(localStorage.getItem('currentUser')).user.role_id != this.ssv.admin_role_id){
            this.router.navigate(['/login']);
        }
        
        this.route.params.subscribe(params => {
            const status = params['status'];
            if(status == 'inactive'){
                this.is_active = '0';
            }
        });
        
        this.populateItems();
        this.populateRoles();
    }
    
    populateItems() {
    this.adms.getUsers()
      .subscribe(itemsList => {
          itemsList = itemsList.filter(x => x.is_active == this.is_active 
                  && x.role_id != this.ssv.admin_role_id);
          this.itemsList = itemsList;
          this.partialItemsList = itemsList;
      });
    }
    
    populateRoles() {
        this.adms.getRoles()
          .subscribe(rolesList => {
              this.rolesList = rolesList;
          });
        }
    
    searchItem() {
      if (this.partialItemsList && this.itemsList) {
        this.partialItemsList = this.itemsList.filter(x => 
            (x.name.toLowerCase().includes(this.search_name.toLowerCase())));
        if(this.role_id !== '0' && this.rolesList){
            this.partialItemsList = this.partialItemsList.filter(x => x.role_id == this.role_id);
        }
      }
    }
    
    onDownloadXls() {
        this.dcs.DownloadUsersList()
            .subscribe(
                res => {
                    saveAs(res, 'UserList.xls', 
                        { type: 'application/vnd.ms-excel' })
                },
                error => {
                    // Display error message
                    console.log(error);
                    this.ssv.showError('Error: Unable to download user list!');
                }
            );
      }
    
    getRole(input: string) {
        if (this.rolesList) {
          return this.rolesList.find(x => x.id == input).title;
        }
        else return "-";
      }
}
