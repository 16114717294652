import { Component, OnInit } from '@angular/core';
import { AdministrationService } from "process-smart-ng6-lib";
import { SharedService } from "src/app/_services/shared.service";
import { Router } from "@angular/router";

@Component({
  selector: 'app-update-constants',
  templateUrl: './update-constants.component.html',
  styleUrls: ['./update-constants.component.css']
})
export class UpdateConstantsComponent implements OnInit {

    constants: any;
    
  constructor(private adms: AdministrationService, private ssv: SharedService,
          private router: Router) { }

  ngOnInit() {
      this.populateConstants();
  }

  populateConstants(){
      this.adms.getConstants()
      .subscribe(constants => this.constants = constants);
  }
  
  onSubmit(){
      this.adms.updateConstants(this.constants)
      .subscribe(
          data => {
              this.ssv.showSuccess('Pricing successfully updated!');
              this.router.navigate(['/update-constants']);
            },
            error => {
                // Display error message
                this.ssv.showError('Unable to update pricing!');
            }
      );
  }
}
