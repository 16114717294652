import { Component, OnInit } from '@angular/core';
import { Email } from "process-smart-ng6-lib";
import { ActivatedRoute } from "@angular/router";
import { FormManagementService, AuthenticationService, AdministrationService, EmailNotificationService, User, Form, Material, InventoryManagementService, Customer } from "process-smart-ng6-lib";
import { SharedService } from "src/app/_services/shared.service";
import { Router } from "@angular/router";

@Component({
  selector: 'app-purchase-order',
  templateUrl: './purchase-order.component.html',
  styleUrls: ['./purchase-order.component.css']
})
export class PurchaseOrderComponent implements OnInit {
    formId: string;
    typeId = '48';
    model: Form;
    fillerUid: string;
    fillerName: string;
    clientId: string;
    roleId: string;
    navigation: string;
    usersList: Array<User>;
    total_cost: number;
    
    customersList: Array<Customer>; //List of all customers (for project name)
    custRecList: Array<Customer>; //List of filtered customers from search
    
    partsList: Array<Material>;     //List of all parts
    suggestedPartsList: Array<Material>;   //Filtered list of parts
    currFocus: number;  //Index of item currently being focused
    matList: Array<any>;    //List of used parts (with select properties)
    
    constructor(private route: ActivatedRoute, private fms: FormManagementService,
          private ssv: SharedService, private aus: AuthenticationService,
          private adms: AdministrationService, private router: Router,
          private ems: EmailNotificationService, private ims: InventoryManagementService) { }
    
    ngOnInit() {
        
        //Get user info from session
        this.fillerUid = JSON.parse(localStorage.getItem('currentUser')).user.uid;
        this.fillerName = JSON.parse(localStorage.getItem('currentUser')).user.name;
        this.roleId = JSON.parse(localStorage.getItem('currentUser')).user.role_id;
        this.clientId = JSON.parse(localStorage.getItem('currentUser')).user.client_id;
        
        this.populateNames();
        this.getPartsList();
        this.getCustomersList();
        
        //Get form ID if specified on route.
        //If specified, load the form.
      this.route.params.subscribe(params => {
            this.formId = params['formId'];
            
            if(this.formId){
                //Load Form
                this.getModel();
            }
            else {
                //Create a new form
                this.model = <Form>{
                    'id': this.formId,
                    'type_id': this.typeId,
                    'status_id': '1',
                    'process_number': '1',
                    'created_date': this.ssv.toHtmlDate(null),
                    'last_modified_date': this.ssv.toHtmlDate(null),
                    'filler_id': this.fillerUid,
                    'modifier_id': this.fillerUid,
                    'inputMap': {
                        'req_date': this.ssv.toHtmlDate(null),
                        'requested_by': this.fillerName
                    },
                    "inputTables": {
                        "materials": []
                    }
                };
            }
        });
    }
    
    populateNames() {
        this.aus.getUsers()
          .subscribe(usersList => this.usersList = usersList);
    }
    
    getModel() {
        this.fms.getForm(this.typeId, this.formId)
          .subscribe(model => {
              model.created_date = this.ssv.toHtmlDate(model.created_date);
              model.last_modified_date = this.ssv.toHtmlDate(null);
              this.model = model;
              this.matList = model.inputTables.materials;
          });
    }
    
    onSubmit() {
      //Collect all materials and labor
      this.model.inputTables.materials = this.matList;
      
      //Upload form data to DB
      if(this.formId){
          //Update an existing form
          this.fms.updateForm(this.model, this.typeId, this.formId)
          .subscribe(
              data => {
                  //If submitted by Dan, email Natalie
                  if(this.roleId == '4' && this.model.process_number == '2'){
                    //Email from Tech to I.Manager (role = 4) to Customer Service (role = 7)
                      const recipient = this.usersList.find(x => x.role_id == '7' && x.is_active == '1');
                      this.sendIManagerSubmissionEmail(recipient.uid);
                      this.ssv.showSuccess('Form successfully submitted!');
                      this.router.navigate(['/view-purchase-order/1']);
                  }
                  else{
                      this.ssv.showSuccess('Form successfully updated!');
                      this.router.navigate(['/view-purchase-order/2']);
                  }
                },
                error => {
                    // Display error message
                    this.ssv.showError('Unable to update form!');
                }
          );
      }
      else{
          //Create a new form
          this.fms.newForm(this.model, this.typeId)
          .subscribe(
              data => {
                  //If submitted by Dan and is not draft, email Natalie
                //If submitted by Dan, email Natalie
                  if(this.roleId == '4' && this.model.process_number == '2'){
                    //Email from Tech to I.Manager (role = 4) to Customer Service (role = 7)
                      const recipient = this.usersList.find(x => x.role_id == '7' && x.is_active == '1');
                      this.sendIManagerSubmissionEmail(recipient.uid);
                      this.ssv.showSuccess('Form successfully submitted!');
                      this.router.navigate(['/view-purchase-order/1']);
                  }
                  else{
                      this.ssv.showSuccess('Form successfully updated!');
                      this.router.navigate(['/view-purchase-order/2']);
                  }
              }
          );
      }
    }
    
    //Submit to Irrigation Manager
    onSubmitProceed(){
          //Update process number if form is submitted (not just saved as draft)
          this.model.process_number = '2';
          this.onSubmit();
      }
    
    //Send email to Irrigation Manager for approval
    sendIManagerSubmissionEmail(recipientId){
        //Get form filler as recipient
        this.aus.getUser(recipientId).subscribe(
            user => {
                console.log(JSON.stringify(user));
                if(user.email){
                    //Send Email
                    const recipientList = [user.email];
                    const ccList = [];
                    const bccList = [];
                    const subject = "Purchase Order - Waiting for Review";
                    const content = user.name + ","
                            + "<br><br>A Purchase Order has been submitted for review."
                            + "<br>Please review and assign a job number to the form."
                    const email: Email = {'subject':subject,
                                            'body':content,
                                            'to': recipientList,
                                            'cc':ccList,
                                            'bcc':bccList};
                    this.ems.sendEmail(email).subscribe(data => {});
                }
                else{
                    console.log('Email recipient not found');
                }
            },
            error => {
                console.log(error);
                // Display error message
                this.ssv.showError('Error: Email recipient not found!');
            });
    }
    
    //MULTI INPUT FIELDS    
    addMaterial() {
        const mat : WO_Material = {'form_id': this.typeId,'material_id':'0'};
        if(!this.matList){
            this.matList = [];
        }
        this.matList.push(mat);
    }
    
    onDeleteMaterial(itemIdx : number){
        let deletedItem = this.matList[itemIdx];
        this.matList.splice(itemIdx, 1);
        this.suggestedPartsList = [];
        
        //Delete from server
        let inputMap = {"inputMap": deletedItem};
        this.fms.deleteMultiInput(inputMap, this.typeId, 'materials', 'material_id')
            .subscribe(data => {}, error => {console.log(error)});
    }
    
    //MATERIAL SEARCH
    getPartsList() {
        this.ims.getMaterials('1')
          .subscribe(list => {
              this.partsList = list;
          });
    }
    
    /**
     * When a suggestion is selected, assign part ID to matList at position == itemNum
     * Empty out suggestions for future use
     * @param itemNum
     */
    onSuggestionSelected(itemNum : number, matId, matName: string) {
        this.matList[itemNum].material_id = ""+matId;
        const part = this.partsList.find(x => x.id == matId);
        if(part){
            this.matList[itemNum].price = "" + part.price;
            this.matList[itemNum].unit = part.unit;
        }
        
        (<HTMLInputElement> document.getElementById('matname'+itemNum)).value = matName;
        this.suggestedPartsList = [];
    }
    
    searchItem(input: string, itemIdx: number) {
        this.currFocus = itemIdx;
        if(!input){
            this.suggestedPartsList = [];
            return;
        }
        if (this.partsList) {
          this.suggestedPartsList = this.partsList.filter(x => 
              (x.description.toLowerCase().includes(input.toLowerCase())) ||
              (x.id == input));
        }
      }
    
    getMaterialName(matId: string) : string {
        if(this.partsList){
            const part = this.partsList.find(x => x.id == matId);
            if(part){
                return part.description;
            }
        }
        return '';
    }
    
    //CUSTOMER SEARCH
    getCustomersList() {
        this.adms.getCustomersByDept('2')
        .subscribe(list => {
            this.customersList = list;
        });
    }
    
    onCustomerSelected(custId: string) {
        const customer : Customer = this.customersList.find(x => x.id == custId);
        this.model.inputMap.job_name = customer.project_name;
        this.model.inputMap.job_num = customer.jobnum;
        this.model.inputMap.client = customer.management;
        this.custRecList = [];
    }
    
    searchCustomer(input: string) {
        if (this.customersList) {
          this.custRecList = this.customersList.filter(x => 
              (x.project_name.toLowerCase().includes(input.toLowerCase())));
        }
      }
}
