import { Component, OnInit } from '@angular/core';
import { Form, Material, FormManagementService, InventoryManagementService } from "process-smart-ng6-lib";
import { ActivatedRoute } from "@angular/router";
import { SharedService } from "src/app/_services/shared.service";
import { Router } from "@angular/router";

@Component({
  selector: 'app-irrigation-repair-parts',
  templateUrl: './irrigation-repair-parts.component.html',
  styleUrls: ['./irrigation-repair-parts.component.css']
})
export class IrrigationRepairPartsComponent implements OnInit {
    formId: string;
    typeId = '47';
    model: Form;
    fillerUid: string;
    clientId: string;
    partsList: Array<Material>;     //List of all parts
    suggestedPartsList: Array<Material>;   //Filtered list of parts
    currFocus: number;  //Index of item currently being focused
    matList: Array<any>;    //List of used parts (with select properties)
    
  constructor(private route: ActivatedRoute, private fms: FormManagementService,
          private ssv: SharedService, private ims: InventoryManagementService, 
          private router: Router) { }

  ngOnInit() {
      this.initFieldNames();
      this.getPartsList();
      
      this.route.params.subscribe(params => {
          this.formId = params['formId'];
          
          if(this.formId){
              //Load Form
              this.getModel();
          }
          else {
              //Form does not exist
              this.router.navigate(['/'])
          }
      });
  }
  
  onSubmit() {
      if(this.model.process_number == '3'){
          this.router.navigate(['/irrigation-repair/'+this.formId]);
          return;
      }
      
      //Validate & Collect all materials
      this.matList.forEach(x => x.quantity? x.quantity : x.quantity = '1');
      this.model.inputTables.materials = this.matList;
      
      //Upload form data to DB
      if(this.formId){
          //Update an existing form
          this.fms.updateForm(this.model, this.typeId, this.formId)
          .subscribe(
              data => {
                  this.ssv.showSuccess('Form successfully updated!');
                  this.router.navigate(['/irrigation-repair/'+this.formId]);
                },
                error => {
                    // Display error message
                    this.ssv.showError('Unable to update form!');
                }
          );
      }
      else{
        //Form does not exist
          this.router.navigate(['/'])
      }
    }
  
  getModel() {
      this.fms.getForm(this.typeId, this.formId)
        .subscribe(model => {
            model.created_date = this.toHtmlDate(model.created_date);
            model.last_modified_date = this.toHtmlDate(null);
            this.model = model;
            this.matList = model.inputTables.materials;
        });
  }
  
//MULTI INPUT FIELDS    
  addMaterial() {
      const mat : WO_Material = {'form_id': this.typeId,'material_id':'0'};
      if(!this.matList){
          this.matList = [];
      }
      this.matList.push(mat);
  }
  
  onDeleteMaterial(itemIdx : number){
      let deletedItem = this.matList[itemIdx];
      this.matList.splice(itemIdx, 1);
      this.suggestedPartsList = [];
      
      //Delete from server
      let inputMap = {"inputMap": deletedItem};
      this.fms.deleteMultiInput(inputMap, this.typeId, 'materials', 'material_id')
          .subscribe(data => {}, error => {console.log(error)});
  }
  
  //MATERIAL SEARCH
  getPartsList() {
      this.ims.getMaterials('1')
        .subscribe(list => {
            this.partsList = list;
        });
  }
  
  /**
   * When a suggestion is selected, assign part ID to matList at position == itemNum
   * Empty out suggestions for future use
   * @param itemNum
   */
  onSuggestionSelected(itemNum : number, matId, matName: string) {
      this.matList[itemNum].material_id = ""+matId;
      (<HTMLInputElement> document.getElementById('matname'+itemNum)).value = matName;
      this.suggestedPartsList = [];
  }
  
  searchItem(input: string, itemIdx: number) {
      this.currFocus = itemIdx;
      if(!input){
          this.suggestedPartsList = [];
          return;
      }
      if (this.partsList) {
        this.suggestedPartsList = this.partsList.filter(x => 
            (x.description.toLowerCase().includes(input.toLowerCase())) ||
            (x.id == input));
      }
    }
  
  getMaterialName(matId: string) : string {
      if(this.partsList){
          const part = this.partsList.find(x => x.id == matId);
          if(part){
              return part.description;
          }
      }
      return '';
  }
  
  toHtmlDate(datestr : string){
      let date;
      if(datestr){
          date = new Date(datestr);
      }
      else{
          date = new Date();
      } 
      const dd = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      const mm = date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
      const yyyy = date.getFullYear();
      
      return yyyy + '-' + mm + '-' + dd;
  }

  //LANGUAGE SUPPORT
  FN_BACK_TO_FORM = "Back to Form";
  FN_MATERIAL = "Material";
  FN_MATERIAL_USED = "Materials Used";
  FN_ADD_MATERIAL = "Add Material";
  FN_QUANTITY = "qty";
  FN_SEARCH_ITEM = "Search part...";
  FN_IRRIGATION_PARTS = "Irrigation Parts";
  
  initFieldNames(){
      const language = JSON.parse(localStorage.getItem('currentUser')).user.language_preference;
      console.log('Language is '+language);
      if(language === 'es'){
          this.FN_BACK_TO_FORM = "Regresar al Formulario";
          this.FN_MATERIAL = "Materiales";
          this.FN_MATERIAL_USED = "Materiales Utilizado";
          this.FN_ADD_MATERIAL = "Añadir Materiales";
          this.FN_QUANTITY = "Cantidad";
          this.FN_SEARCH_ITEM = "Buscar...";
          this.FN_IRRIGATION_PARTS = "Partes de Irrigación";
      }
  }
  
  //DIAGNOSTIC
  get mdiagnostic() { return JSON.stringify(this.matList); }
}
