import { Component, OnInit } from '@angular/core';
import { Formtype, Area, RolePermission } from 'process-smart-ng6-lib';
import { FormManagementService, AdministrationService, AuthenticationService } from 'process-smart-ng6-lib';
import { Router } from "@angular/router";
import { SharedService } from "src/app/_services/shared.service";

@Component({
  selector: 'app-dashboard-sidebar',
  templateUrl: './dashboard-sidebar.component.html',
  styleUrls: ['./dashboard-sidebar.component.css']
})
export class DashboardSidebarComponent implements OnInit {
    clientId: number;
    formsList: Array<Formtype>;
    authorizedFeatures: Array<string>;
    authorizedForms: Array<Formtype>;
    allowedList: Array<string>;
    roleId: string;

    // TODO: populate form types of this client
  constructor(private fs: FormManagementService, private as: AdministrationService,
          private aus: AuthenticationService, private router: Router,
          private ssv: SharedService) {
  }

  ngOnInit() {
      this.roleId = JSON.parse(localStorage.getItem('currentUser')).user.role_id;
      
      //Get counts for alert
      this.ssv.countForm('wt_3','49',null,'3');
      this.ssv.countForm('irf_3','47',null,'3');
      this.ssv.countForm('wo_1','45',null,'1');
      
      this.retrieveFormtypes();
      this.retrieveRolePermissions();
      this.retrieveFormPermissions();
  }

  retrieveFormtypes(): void {
      this.fs.getFormTypes()
      .subscribe(itemsList => {
          this.formsList = itemsList;
          if(!this.authorizedForms && this.allowedList){
              this.authorizedForms = itemsList.filter(x => this.allowedList.includes(x.id));
          }
          
          //Remove refresh Count from localStorage
          localStorage.removeItem("numRefresh");
        },
        error => {
            //Refresh once.
            const numRefresh = localStorage.getItem("numRefresh");
            if(numRefresh){
                if(+numRefresh >= 3){
                    localStorage.removeItem("numRefresh");
                    this.router.navigate(['/login']);
                }
                else{
                    localStorage.setItem("numRefresh", "" + (+localStorage.getItem("numRefresh") + 1));
                 // @ts-ignore
                    Android.reloadWebview();
                    window.location.reload();
                }
            }
            else {
                localStorage.setItem("numRefresh", "1");
                
             // @ts-ignore
                Android.reloadWebview();
                window.location.reload();
            }
        });
  }

  retrieveRolePermissions(): void {
      this.aus.getRolePermissions(this.roleId)
      .subscribe(itemsList => {
          this.authorizedFeatures = itemsList.map(x => x.feature_id);
    },
    error => {
    });
  }
  
  retrieveFormPermissions(): void {
      this.aus.getFormPermissions(this.roleId)
      .subscribe(itemsList => {
          this.allowedList = itemsList.map(x => x.form_type_id);
          if(!this.authorizedForms && this.formsList){
              this.authorizedForms = this.formsList.filter(x => this.allowedList.includes(x.id));
          }
    },
    error => {
    });
  }
  
  countForm(formId){
      return '';
  }
}
