import { Component, OnInit } from '@angular/core';
import { AdministrationService, Customer } from "process-smart-ng6-lib";

@Component({
  selector: 'app-update-customers-list',
  templateUrl: './update-customers-list.component.html',
  styleUrls: ['./update-customers-list.component.css']
})
export class UpdateCustomersListComponent implements OnInit {
    file:any;
    customersDict: any;
    customersDictKeys: Array<string>;
    parsedCustomersList: Array<Customer>;

  constructor(private adms: AdministrationService) { }

  ngOnInit() {
  }

  fileChanged(e) {
      this.file = e.target.files[0];
  }
  
  populateCustomersList() {
      /*let fileReader = new FileReader();
      fileReader.onload = (e) => {
          //Skip the first line
          
          //Parse the result (in Customer array)
          const cArr = fileReader.result.split(/\r?\n/);
          cArr.forEach(crow => {
              const c = crow.split(',');
              if(c){
                  let deptId = '2';
                  if(c[1] && c[1].toLowerCase().includes('janitorial')){
                      deptId = '6';
                  }
                  const customer = <Customer> {
                          client_id: '1',
                          project_name: c[1] ? c[1].trim() : '',
                          jobnum: c[0] ? c[0].trim() : '',
                          contract_amt: '0',
                          address: c[2] ? c[2].trim() : '',
                          city: c[3] ? c[3].trim() : '',
                          management: c[4] ? c[4].trim() : '',
                          contact: c[5] ? c[5].trim() : '',
                          phone: c[6] ? c[6].trim() : '',
                          email: c[11] ? c[11].trim() : '',
                          department_id: deptId
                  }
                  
                  //Send request to server
                  this.adms.newCustomer(customer)
                      .subscribe(data => {
                          console.log('created');
                      },
                      error => {
                          console.log('error for '+c[0]+ ' - ' + c[1]);
                      })
              }
          })
          
      }
      fileReader.readAsText(this.file);*/
  }
}
