import { Component, OnInit } from '@angular/core';
import { Form, FormManagementService, AdministrationService, AuthenticationService, User, Email, EmailNotificationService } from "process-smart-ng6-lib";
import { ActivatedRoute } from "@angular/router";
import { SharedService } from "src/app/_services/shared.service";
import { Router } from "@angular/router";
import { S3Manager } from "src/app/api/S3Manager";

@Component({
  selector: 'app-irrigation-repair',
  templateUrl: './irrigation-repair.component.html',
  styleUrls: ['./irrigation-repair.component.css']
})
export class IrrigationRepairComponent implements OnInit {
    formId: string;
    typeId = '47';
    model: Form;
    fillerUid: string;
    fillerName: string;
    clientId: string;
    roleId: string;
    matList: Array<any>;    //List of used parts (with select properties)
    navigation: string;
    goToParts = false;
    usersList: Array<User>;
    
    attachment1: File;
    attachment2: File;
    attachment3: File;
    attachment4: File;
    attachment5: File;
    attachment6: File;
    attachment7: File;
    attachment8: File;
    attachment9: File;
    attachment10: File;
    
    constructor(private route: ActivatedRoute, private fms: FormManagementService,
          private ssv: SharedService, private aus: AuthenticationService,
          private adms: AdministrationService, private router: Router,
          private ems: EmailNotificationService) { }
    
    ngOnInit() {
        //Get user info from session
        this.fillerUid = JSON.parse(localStorage.getItem('currentUser')).user.uid;
        this.fillerName = JSON.parse(localStorage.getItem('currentUser')).user.name;
        this.roleId = JSON.parse(localStorage.getItem('currentUser')).user.role_id;
        this.clientId = JSON.parse(localStorage.getItem('currentUser')).user.client_id;
        this.initFieldNames();
        
        this.populateNames();
        
        //Get form ID if specified on route.
        //If specified, load the form.
      this.route.params.subscribe(params => {
            this.formId = params['formId'];
            
            if(this.formId){
                //Load Form
                this.getModel();
            }
            else {
                //Create a new form
                this.model = <Form>{
                    'id': this.formId,
                    'type_id': this.typeId,
                    'status_id': '1',
                    'process_number': '1',
                    'created_date': this.toHtmlDate(null),
                    'last_modified_date': this.toHtmlDate(null),
                    'filler_id': this.fillerUid,
                    'modifier_id': this.fillerUid,
                    'inputMap': {
                        'date': this.toHtmlDate(null),
                        'tech_name': this.fillerName
                    },
                    "inputTables": {
                        "materials": []
                    }
                };
            }
        });
    }
    
    populateNames() {
        this.aus.getUsers()
          .subscribe(usersList => this.usersList = usersList);
    }
    
    getModel() {
        this.fms.getForm(this.typeId, this.formId)
          .subscribe(model => {
              model.created_date = this.toHtmlDate(model.created_date);
              model.last_modified_date = this.toHtmlDate(null);
              this.model = model;
              this.matList = model.inputTables.materials;
          });
    }
    
    onSubmit() {
      //Collect all materials and labor
      this.model.inputTables.materials = this.matList;
      
      //Ensure number fields are filled
      if(!this.model.inputMap.tech_hours || isNaN(this.model.inputMap.tech_hours) ||
              this.model.inputMap.tech_hours.replace(/^\s+/, '').replace(/\s+$/, '') === ''){
          this.model.inputMap.tech_hours = '0';
      }
      if(!this.model.inputMap.ot_hours || isNaN(this.model.inputMap.ot_hours) ||
              this.model.inputMap.ot_hours.replace(/^\s+/, '').replace(/\s+$/, '') === ''){
          this.model.inputMap.ot_hours = '0';
      }
      if(!this.model.inputMap.labor_hours || isNaN(this.model.inputMap.labor_hours) ||
              this.model.inputMap.labor_hours.replace(/^\s+/, '').replace(/\s+$/, '') === ''){
          this.model.inputMap.labor_hours = '0';
      }
      
    //Check if there are attachments to upload
      let filesToUpload = [null,null,null,null,null];
      let fileNames = ["","","","",""];
      let numUploads = 0;
      for(let num = 1; num <= 5; num++) {
          if(this.model.inputMap['attachment'+num] && this['attachment'+num]){
              filesToUpload[numUploads] = this['attachment'+num];
              fileNames[numUploads] = this.model.inputMap['attachment'+num];
              numUploads++;
          }
      }
      
      console.log('Number of uploads: '+numUploads);
      
      if(numUploads > 0){
          this.ssv.activateUploadModal();
      }
      
      //Upload form data to DB
      if(this.formId){
          //Update an existing form
          this.fms.updateForm(this.model, this.typeId, this.formId)
          .subscribe(
              data => {
                  let navUrl = this.navigation + (this.goToParts ? this.formId : "");
                  if(numUploads > 0){
                      S3Manager.uploadAllFormAttachments(filesToUpload, 
                              fileNames,
                              this.clientId,
                              navUrl,
                              numUploads,
                              this.ssv,
                              this.router);
                  }
                  this.onUpdateFormSuccess();
                  if(numUploads == 0){
                      //Redirect immediately if no uploads
                      this.router.navigate([navUrl]);
                  }
                },
                error => {
                    // Display error message
                    this.ssv.showError('Unable to update form!');
                }
          );
      }
      else{
          //Create a new form
          this.fms.newForm(this.model, this.typeId)
          .subscribe(
              data => {
                  this.formId = data['formId'];
                  let navUrl = this.navigation + (this.goToParts ? data['formId'] : "");
                  if(numUploads > 0){
                      S3Manager.uploadAllFormAttachments(filesToUpload, 
                              fileNames,
                              this.clientId,
                              navUrl,
                              numUploads,
                              this.ssv,
                              this.router);
                  }
                  this.onCreateFormSuccess(data['formId']);
                  if(numUploads == 0){
                      //Redirect immediately if no uploads
                      this.router.navigate([navUrl]);
                  }
                },
                error => {
                    // Display error message
                    this.ssv.showError('Unable to create form!');
                }
          );
      }
    }
    
    onCreateFormSuccess(formId){
        this.ssv.showSuccess('Form successfully updated!');
    }
    
    onUpdateFormSuccess(){
        this.ssv.showSuccess('Form successfully updated!');
        
        if(this.model.process_number == '2'){
            //Email from Tech to I.Manager (role = 4)
            const recipient = this.usersList.find(x => x.role_id == '4' && x.is_active == '1');
            this.sendTechSubmissionEmail(recipient.uid);
        }
        else if(this.model.process_number == '3'){
            //Send email to Irrigation Staff (role = 21)
            const recipient = this.usersList.find(x => x.role_id == '21' && x.is_active == '21');
            this.sendIRFManagerApprovalEmail(recipient.uid);
        }
    }
    
    //Submit to Irrigation Manager
    onSubmitProceed(){
      //No more submission after manager approval (step 3 onwards)
        if(this.model.process_number == '3'){
            return;
        }
        
        //Update process number if form is submitted (not just saved as draft)
        this.navigation = '/view-irrigation-repair/3';
        if(this.roleId == '4'){
            //Irrigation Manager doesn't need approval
            this.model.process_number = '3';
            this.navigation = '/view-irrigation-repair/3';
        }
        else{
            this.model.process_number = '2';
            this.navigation = '/view-irrigation-repair/2';
        }
          this.onSubmit();
      }
    
    //Submit and redirect to view forms page
    onSubmitBack(){
          this.navigation = '/view-irrigation-repair/'+this.model.process_number;
        //No more submission after manager approval (step 3 onwards)
          if(this.model.process_number == '3'){
              this.router.navigate([this.navigation]);
              return;
          }
          this.onSubmit();
      }
    
    //Submit and redirect to parts list page
    onSubmitPartsList(){
          this.goToParts = true;
          this.navigation = '/irrigation-repair-parts/';
          
        //No more submission after manager approval (step 3 onwards)
          if(this.model.process_number == '3'){
              this.router.navigate([this.navigation+this.formId]);
              return;
          }
          
          this.onSubmit();
      }
    
    onDownloadAttachment(num: string) {
      const filename = this.model.inputMap['attachment'+num];
      S3Manager.downloadFormAttachment(filename, this.clientId);
    }
    
    //Filename = form[GUID]_att[num].[ext]
    onFileAdded(files, num) {
      if (files === undefined || files.length == 0) {
          this['attachment'+num] = null;
          this.model['attachment'+num] = '';
      }
      else{
          const ext = files[0].name.substr(files[0].name.lastIndexOf('.'));
          this['attachment'+num] = files[0];
          this.model.inputMap['attachment'+num] = 'form'+this.newGuid()+'_att'+num+ext;
      }
    }
    
    //Create Unique ID
    newGuid() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
          const r = Math.random() * 16 | 0, v = c === 'x' ? r : ( r & 0x3 | 0x8 );
          return v.toString(16);
      });
    }
    
    toHtmlDate(datestr : string){
        let date;
        if(datestr){
            date = new Date(datestr);
        }
        else{
            date = new Date();
        } 
        const dd = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
        const mm = date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
        const yyyy = date.getFullYear();
        
        return yyyy + '-' + mm + '-' + dd;
    }
    
    //Send email to Irrigation Manager for approval
    sendTechSubmissionEmail(recipientId){
        //Get form filler as recipient
        this.aus.getUser(recipientId).subscribe(
            user => {
                console.log(JSON.stringify(user));
                if(user.email){
                    //Send Email
                    const recipientList = [user.email];
                    const ccList = [];
                    const bccList = [];
                    const subject = "Pending Approval - Irrigation Repair";
                    const content = user.name + ","
                            + "<br><br>An Irrigation repair has been completed."
                            + "<br>Please review and assign a job number to the repair forms."
                    const email: Email = {'subject':subject,
                                            'body':content,
                                            'to': recipientList,
                                            'cc':ccList,
                                            'bcc':bccList};
                    console.log(JSON.stringify(email));
                    this.ems.sendEmail(email).subscribe(data => {});
                }
                else{
                    console.log('Email recipient not found');
                }
            },
            error => {
                console.log(error);
                // Display error message
                this.ssv.showError('Error: Email recipient not found!');
            });
    }
    
    //Send to Irrigation Staff for Approval & Job Number
    sendIRFManagerApprovalEmail(recipientId){
        //Get form filler as recipient
        this.aus.getUser(recipientId).subscribe(
            user => {
                console.log(JSON.stringify(user));
                if(user.email){
                    //Send Email
                    const recipientList = [user.email];
                    const ccList = [];
                    const bccList = [];
                    const subject = "Irrigation Repair Job Number Request";
                    const content = user.name + ","
                            + "<br><br>An Irrigation Repair requires your approval."
                            + "<br>Please login to the Dashboard to review the repair."
                    const email: Email = {'subject':subject,
                                            'body':content,
                                            'to': recipientList,
                                            'cc':ccList,
                                            'bcc':bccList};
                    console.log(JSON.stringify(email));
                    this.ems.sendEmail(email).subscribe(data => {});
                }
                else{
                    console.log('Email recipient not found');
                }
            },
            error => {
                console.log(error);
                // Display error message
                this.ssv.showError('Error: Email recipient not found!');
            });
    }
    
    //DIAGNOSTIC
    get diagnostic() { return JSON.stringify(this.model); }
    get mdiagnostic() { return JSON.stringify(this.matList); }
    
    getS3FormPicUrl(filename) {
        return S3Manager.getFormAttachmentUrl(filename, this.clientId);
    }
    
    //Field Names
    FN_SAVE_BACK = "Save & Back";
    FN_PARTS_LIST = "Parts List";
    FN_TITLE = "Irrigation Repair";
    FN_JOB_INFO = "Job Information";
    FN_TECH_NAME = "Tech Name";
    FN_JOB_NAME = "Job Name";
    FN_JOB_LOCATION = "Job Location";
    FN_WORK_HOURS = "Work Hours";
    FN_DATE = "Date";
    FN_START_TIME = "Start Time";
    FN_END_TIME = "End Time";
    FN_TECH_HOURS = "Tech Hours";
    FN_OT_HOURS = "O/T Hours";
    FN_LABOR_HOURS = "Labor Hours";
    FN_WORK_DETAILS = "Work Details";
    FN_PROBLEM = "Problem";
    FN_REPAIR_MADE = "Repair Made";
    FN_EMERGENCY_CALL = "Emergency Call";
    FN_ON_CHECKLIST = "On Checklist";
    FN_NISSHO_AREA = "Nissho Area";
    FN_CS_CALL = "Customer Service Call";
    FN_LEGEND_IN = "Legend in Controllers";
    FN_CHART_IN = "Charts in Controllers";
    FN_JOB_COMPLETE = "Job Complete";
    FN_PO_USED = "PO Used";
    FN_SUBMIT_FORM = "Submit Form";
    FN_YES = "Yes";
    FN_NO = "No";
    FN_BACK_TO_FORM = "Back to Form";
    FN_PARTS_USED = "Parts List";
    FN_IRRIGATION_PARTS = "Irrigation Parts";
    FN_MATERIAL = "Material";
    FN_ADD_MATERIAL = "Add Material";
    FN_QUANTITY = "qty";
    ADD_PICS = "Upload Pictures";
    
    initFieldNames(){
        const language = JSON.parse(localStorage.getItem('currentUser')).user.language_preference;
        if(language === 'es'){
            this.FN_SAVE_BACK = "Guardar & Retroceder";
            this.FN_PARTS_LIST = "Lista de Piezas";
            this.FN_TITLE = "Reparación de Irrigación";
            this.FN_JOB_INFO = "Información de Trabajo";
            this.FN_TECH_NAME = "Nombre de Técnico";
            this.FN_JOB_NAME = "Nombre de trabajo";
            this.FN_JOB_LOCATION = "Lugar de trabajo";
            this.FN_WORK_HOURS = "Horas de trabajo";
            this.FN_DATE = "Fecha";
            this.FN_START_TIME = "Hora de comienzo";
            this.FN_END_TIME = "Hora del fin";
            this.FN_TECH_HOURS = "Horas de trabajo";
            this.FN_OT_HOURS = "Horas Extras";
            this.FN_LABOR_HOURS = "Horas totales";
            this.FN_WORK_DETAILS = "Detalles de trabajo";
            this.FN_PROBLEM = "Problema";
            this.FN_REPAIR_MADE = "Reparación hecho";
            this.FN_EMERGENCY_CALL = "Llamada de emergencia";
            this.FN_ON_CHECKLIST = "En lista de verificación";
            this.FN_NISSHO_AREA = "área de Nissho";
            this.FN_CS_CALL = "Llamada de servicio de cliente";
            this.FN_LEGEND_IN = "Leyenda en controles";
            this.FN_CHART_IN = "Gráfica en controles";
            this.FN_JOB_COMPLETE = "Trabajo completado";
            this.FN_PO_USED = "PO usado";
            this.FN_SUBMIT_FORM = "Enviar";
            this.FN_YES = "Si";
            this.FN_NO = "No";
            
            this.FN_BACK_TO_FORM = "Regresar al Formulario";
            this.FN_PARTS_USED = "Partes Usados";
            this.FN_IRRIGATION_PARTS = "Partes de Irrigación";
            this.FN_MATERIAL = "Materiales";
            this.FN_ADD_MATERIAL = "Añadir Materiales";
            this.FN_QUANTITY = "Cantidad";
            this.ADD_PICS = "Añadir Foto";
        }
    }
}
