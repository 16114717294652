import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { routing } from './app.routing';
import { ApiModule } from 'process-smart-ng6-lib';

import {
    RECAPTCHA_SETTINGS,
    RecaptchaSettings,
    RecaptchaLoaderService,
    RecaptchaModule,
} from 'ng-recaptcha';

import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { DashboardSidebarComponent } from './dashboard-sidebar/dashboard-sidebar.component';
import { DashboardTopbarComponent } from './dashboard-topbar/dashboard-topbar.component';
import { DashboardContentComponent } from './dashboard-content/dashboard-content.component';
import { RouterModule, Routes } from '@angular/router';
import { AppUpdatesComponent } from './general/app-updates/app-updates.component';
import { BugReportComponent } from './general/bug-report/bug-report.component';
import { UserIdleModule } from 'angular-user-idle';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { ViewWorkOrderComponent } from './forms/view-work-order/view-work-order.component';
import { ViewStockTransferComponent } from './forms/view-stock-transfer/view-stock-transfer.component';
import { ViewIrrigationRepairComponent } from './forms/view-irrigation-repair/view-irrigation-repair.component';
import { ViewPurchaseOrderComponent } from './forms/view-purchase-order/view-purchase-order.component';
import { ViewWalkthroughComponent } from './forms/view-walkthrough/view-walkthrough.component';
import { ViewCostSummaryComponent } from './forms/view-cost-summary/view-cost-summary.component';
import { ViewMWorkOrderComponent } from './forms/view-m-work-order/view-m-work-order.component';
import { ViewCWorkOrderComponent } from './forms/view-c-work-order/view-c-work-order.component';
import { ViewMaterialsComponent } from './inventory/view-materials/view-materials.component';
import { ViewContainersComponent } from './inventory/view-containers/view-containers.component';
import { ViewCustomersComponent } from './customers/view-customers/view-customers.component';
import { WorkOrderComponent } from './forms/fill/work-order/work-order.component';
import { WalkthroughComponent } from './forms/fill/walkthrough/walkthrough.component';
import { IrrigationRepairComponent } from './forms/fill/irrigation-repair/irrigation-repair.component';
import { ViewEmployeesComponent } from './administration/view-employees/view-employees.component';
import { ViewRolesComponent } from './administration/view-roles/view-roles.component';
import { FormEmployeesComponent } from './administration/form-employees/form-employees.component';
import { FormRolesComponent } from './administration/form-roles/form-roles.component';
import { StockTransferComponent } from './forms/fill/stock-transfer/stock-transfer.component';
import { IrrigationRepairPartsComponent } from './forms/fill/irrigation-repair-parts/irrigation-repair-parts.component';
import { CookieService } from "ngx-cookie-service";
import { FormMaterialsComponent } from './inventory/form-materials/form-materials.component';
import { FormContainersComponent } from './inventory/form-containers/form-containers.component';
import { ViewTruckInventoryComponent } from './inventory/view-truck-inventory/view-truck-inventory.component';
import { FormTruckInventoryComponent } from './inventory/form-truck-inventory/form-truck-inventory.component';
import { ViewStockTransferStatisticsComponent } from './view-stock-transfer-statistics/view-stock-transfer-statistics.component';
import { StartJobComponent } from './forms/fill/start-job/start-job.component';
import { MWorkOrderComponent } from './forms/fill/m-work-order/m-work-order.component';
import { UpdateConstantsComponent } from './general/update-constants/update-constants.component';
import { FormCustomersComponent } from './customers/form-customers/form-customers.component';
import { ViewDataUsageComponent } from './general/view-data-usage/view-data-usage.component';
import { PurchaseOrderComponent } from './forms/fill/purchase-order/purchase-order.component';
import { ViewStockByTruckComponent } from './view-stock-by-truck/view-stock-by-truck.component';
import { UpdateCustomersListComponent } from './populate/update-customers-list/update-customers-list.component';
import { AuthTknInterceptor } from "src/app/_services/AuthTknInterceptor";

const appRoutes: Routes = [
  { path: 'login',
    component: LoginComponent,
    data: { title: 'Login | WHP' }
  },
  { path: 'dashboard',
    component: DashboardComponent,
    data: { title: 'Dashboard | WHP' }
  },
  { path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full'
  },
  { path: '**', component: DashboardComponent }
];

const globalSettings: RecaptchaSettings = {siteKey: '6Leu6ZoUAAAAALOkQhWaXPKJDdacexAejMCT7PnI'};

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    LoginComponent,
    DashboardSidebarComponent,
    DashboardTopbarComponent,
    DashboardContentComponent,
    AppUpdatesComponent,
    BugReportComponent,
    ViewWorkOrderComponent,
    ViewStockTransferComponent,
    ViewIrrigationRepairComponent,
    ViewPurchaseOrderComponent,
    ViewWalkthroughComponent,
    ViewCostSummaryComponent,
    ViewMWorkOrderComponent,
    ViewCWorkOrderComponent,
    ViewMaterialsComponent,
    ViewContainersComponent,
    ViewCustomersComponent,
    WorkOrderComponent,
    WalkthroughComponent,
    IrrigationRepairComponent,
    ViewEmployeesComponent,
    ViewRolesComponent,
    FormEmployeesComponent,
    FormRolesComponent,
    StockTransferComponent,
    IrrigationRepairPartsComponent,
    FormMaterialsComponent,
    FormContainersComponent,
    ViewTruckInventoryComponent,
    FormTruckInventoryComponent,
    ViewStockTransferStatisticsComponent,
    StartJobComponent,
    MWorkOrderComponent,
    UpdateConstantsComponent,
    FormCustomersComponent,
    ViewDataUsageComponent,
    PurchaseOrderComponent,
    ViewStockByTruckComponent,
    UpdateCustomersListComponent
  ],
  entryComponents: [
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatDialogModule,
    ApiModule,
    // Idle: seconds before warning pops up, Timeout: seconds before app logs out after warning,
    // Ping(optional): seconds before periodic action fires
    // UserIdleModule.forRoot({idle: 10, timeout: 10, ping: 15}),

    // Refresh token every 15 minutes, inactivity logout after 30 minutes
    UserIdleModule.forRoot({idle: 10, timeout: 1800, ping: 900}),
    routing,
    RecaptchaModule.forRoot()
  ],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy},
              {provide: RECAPTCHA_SETTINGS, useValue: globalSettings},
              {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: false}},
              CookieService,
              {
                  provide: HTTP_INTERCEPTORS,
                  useClass: AuthTknInterceptor,
                  multi: true
                }
              ],
  bootstrap: [AppComponent]
})
export class AppModule { }
