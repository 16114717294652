import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { AuthenticationService } from "process-smart-ng6-lib";
import { SharedService } from "src/app/_services/shared.service";

@Component({
  selector: 'app-form-roles',
  templateUrl: './form-roles.component.html',
  styleUrls: ['./form-roles.component.css']
})
export class FormRolesComponent implements OnInit {

    constructor(private adms: AuthenticationService, private ssv: SharedService, 
            private router: Router) { }
    
    ngOnInit() {
        //Authentication
        if(JSON.parse(localStorage.getItem('currentUser')).user.role_id != '1'){
            this.router.navigate(['/login']);
        }
    }

}
