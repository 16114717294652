import * as AWS from 'aws-sdk';
import * as S3 from 'aws-sdk/clients/s3';
import { saveAs } from 'file-saver';
import { SharedService } from "src/app/_services/shared.service";
import { Router } from "@angular/router";

/**
  * This class handles Upload and     static uploadInstructorAttachment(arg0: any, arg1: any): any {
        throw new Error("Method not implemented.");
    }
Download operations from Amazon S3
  */
export class S3Manager {
    private static s3_BasePath = 'https://process-smart-attachments.s3.amazonaws.com';
    private static s3_AssetPath = 'assets/';
    private static s3_InstructorPath = 'instructors/';
    private static s3_ServiceLogPath = 'service-log/';
    private static s3_FormsPath = 'forms/';
    
    //Credentials
    private static accessKeyId = 'AKIAJPF26MNTRGMYQECQ';
    private static secretAccessKey = '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';
    private static region = 'us-west-1';
    private static bucket = 'process-smart-attachments';
    
    //Upload progress
    public static num_uploads_total;
    public static num_uploads_done;
    public static upload_error = false;
    
    static getAssetAttachmentKey(filename: string) {
        return this.s3_AssetPath + filename;
    }
    
    static getInstructorAttachmentKey(filename: string) {
        return this.s3_InstructorPath + filename;
    }
    
    static getServiceLogAttachmentKey(filename: string, clientId: string) {
        return this.s3_ServiceLogPath + clientId + '/' + filename;
    }
    
    static getFormAttachmentKey(filename: string, clientId: string) {
        return this.s3_FormsPath + clientId + '/' + filename;
    }
    
    static getFormAttachmentUrl(filename: string, clientId: string) {
        return 'https://s3-'+this.region+'.amazonaws.com/'+this.bucket+'/' + 
                    this.getFormAttachmentKey(filename, clientId);
    }
    
    static downloadAssetAttachment(filename: string){
        const accessKeyId = this.accessKeyId;
        const secretAccessKey = this.secretAccessKey;
        const region = this.region;
        
        const mimeType = this.getMimeType(filename);
        const s3 = new S3(
                {
                    accessKeyId: accessKeyId,
                    secretAccessKey: secretAccessKey,
                    region: region
                }
            );
        const params = {
                Bucket: this.bucket,
                Key: S3Manager.getAssetAttachmentKey(filename),
            };
        s3.getObject(params, function(err, data){
            if (err) console.log(err); 
            else{
                const blob = new Blob([data.Body as BlobPart], { type: mimeType });
                saveAs(blob, filename);
            }
        });
    }
    
    static downloadFormAttachment(filename: string, clientId: string){
        const accessKeyId = this.accessKeyId;
        const secretAccessKey = this.secretAccessKey;
        const region = this.region;
        
        const mimeType = this.getMimeType(filename);
        const s3 = new S3(
                {
                    accessKeyId: accessKeyId,
                    secretAccessKey: secretAccessKey,
                    region: region
                }
            );
        const params = {
                Bucket: this.bucket,
                Key: S3Manager.getFormAttachmentKey(filename,clientId),
            };
        s3.getObject(params, function(err, data){
            if (err) console.log(err); 
            else{
                const blob = new Blob([data.Body as BlobPart], { type: mimeType });
                saveAs(blob, filename);
            }
        });
    }
    
    static downloadInstructorAttachment(filename: string){
        const accessKeyId = this.accessKeyId;
        const secretAccessKey = this.secretAccessKey;
        const region = this.region;
        
        const mimeType = this.getMimeType(filename);
        const s3 = new S3(
                {
                    accessKeyId: accessKeyId,
                    secretAccessKey: secretAccessKey,
                    region: region
                }
            );
        const params = {
                Bucket: this.bucket,
                Key: S3Manager.getInstructorAttachmentKey(filename),
            };
        s3.getObject(params, function(err, data){
            if (err) console.log(err); 
            else{
                const blob = new Blob([data.Body as BlobPart], { type: mimeType });
                saveAs(blob, filename);
            }
        });
    }
    
    static uploadInstructorAttachment(file, filename){
        const contentType = file.type;
        const accessKeyId = this.accessKeyId;
        const secretAccessKey = this.secretAccessKey;
        const region = this.region;
        
        const s3 = new S3(
                {
                    accessKeyId: accessKeyId,
                    secretAccessKey: secretAccessKey,
                    region: region
                }
            );
        const params = {
                Bucket: this.bucket,
                Key: S3Manager.getInstructorAttachmentKey(filename),
                Body: file,
                ACL: 'public-read',
                ContentType: contentType
            };
        s3.upload(params, function(err, data){
            if (err){
                console.log(err);
                return false;
            }
        });
    }
    
    private static uploadAttachment(file, key, redirect_to?: string, ssv?: SharedService, router?: Router){
        const contentType = file.type;
        const accessKeyId = this.accessKeyId;
        const secretAccessKey = this.secretAccessKey;
        const region = this.region;
        
        this.upload_error = false;
        
        const s3 = new S3(
                {
                    accessKeyId: accessKeyId,
                    secretAccessKey: secretAccessKey,
                    region: region
                }
            );
        const params = {
                Bucket: this.bucket,
                Key: key,
                Body: file,
                ACL: 'public-read',
                ContentType: contentType
            };
        
        s3.upload(params, function(err, data){
            if (err){
                //Retry
                console.log('Upload Error, Retrying...');
                s3.upload(params, function(err1, data1){
                    if(err1){
                        this.upload_error = true;
                        return false;
                    }
                    else if(redirect_to){
                        S3Manager.num_uploads_done++;
                        if(S3Manager.num_uploads_done >= S3Manager.num_uploads_total || S3Manager.num_uploads_total == 1){
                          //Reset upload modal
                            if(ssv){
                                ssv.closeUploadModal();
                            
                                //Redirect the page
                                ssv.showSuccess('Form successfully updated!');
                            }
                            if(router)
                                router.navigate([redirect_to]);
                        }
                        return;
                    }
                });
            }
            else if(redirect_to){
                S3Manager.num_uploads_done++;
                console.log('File Upload Progress: '+S3Manager.num_uploads_done+' of '+S3Manager.num_uploads_total);
                if(S3Manager.num_uploads_done == S3Manager.num_uploads_total){
                    if(ssv){
                        //Reset upload modal
                        ssv.closeUploadModal();
    
                        //Redirect the page
                        ssv.showSuccess('Form successfully updated!');
                    }
                    if(router)
                        router.navigate([redirect_to]);
                }
                return;
            }else{
                console.log('Redirect to: is null');
            }
        });
    }
    
    static uploadAssetAttachment(file, filename){
        this.uploadAttachment(file, this.getAssetAttachmentKey(filename));
    }
    
    static uploadAllFormAttachments(files, filenames: string[], clientId, redirect_to?: string, uploads_total?: number,
            ssv?: SharedService, router?: Router){
        if(!files || !filenames || filenames.length <= 0){
            return;
        }
        console.log('Uploading all attachments: '+uploads_total);
        
        S3Manager.num_uploads_done = 0;
        if(uploads_total){
            S3Manager.num_uploads_total = uploads_total;
        }else{
            S3Manager.num_uploads_total = 1;
        }
        
        for(let i = 0; i < filenames.length; i++){
            console.log('File Upload? '+(files[i] != null));
            if(files[i]){
                this.uploadAttachment(files[i], this.getFormAttachmentKey(filenames[i], clientId), redirect_to, ssv, router);
            }
        }
    }
    
    static uploadFormAttachment(file, filename: string, clientId: string, redirect_to?: string){
        this.uploadAttachment(file, this.getFormAttachmentKey(filename, clientId), redirect_to);
    }
    
    static downloadServiceLogAttachment(filename: string, clientId: string){
        const accessKeyId = this.accessKeyId;
        const secretAccessKey = this.secretAccessKey;
        const region = this.region;
        
        const mimeType = this.getMimeType(filename);
        const s3 = new S3(
                {
                    accessKeyId: accessKeyId,
                    secretAccessKey: secretAccessKey,
                    region: region
                }
            );
        
        const params = {
                Bucket: this.bucket,
                Key: S3Manager.getServiceLogAttachmentKey(filename, clientId),
            };
        s3.getObject(params, function(err, data){
            if (err) console.log(err, err); 
            else{
                const blob = new Blob([data.Body as BlobPart], { type: mimeType });
                saveAs(blob, filename);
            }
        });
    }
    
    static getMimeType(filename: string): string {
        if(filename == null || filename == ''){
            return null;
        }
        //Get extension's lowercase
        const filetype = filename.substr(filename.lastIndexOf('.')+1).toLowerCase();
        
        switch(filetype) {
            case 'jpg': return 'image/jpeg';
            case 'png': return 'image/png';
            case 'pdf': return 'application/pdf';
            case 'doc': return 'application/msword';
            case 'docx': return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
            case 'xls': return 'application/vnd.ms-excel';
            case 'xlsx': return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            case 'txt': return 'text/plain';
            default: return null;
        }
    }
}