import { Component, OnInit } from '@angular/core';
import { Form, FormManagementService } from "process-smart-ng6-lib";
import { SharedService } from "src/app/_services/shared.service";
import { Router } from "@angular/router";

@Component({
  selector: 'app-start-job',
  templateUrl: './start-job.component.html',
  styleUrls: ['./start-job.component.css']
})
export class StartJobComponent implements OnInit {

    typeId = '45';  //Work Order
    typeId2 = '54'; //Stock Transfer
    model: Form;    //Work Order
    model2: Form;    //Stock Transfer
    fillerUid: string;
    job_number: string;
    jobNumsAvailable: Array<string>;
    
  constructor(private fms: FormManagementService, private ssv: SharedService,
          private router: Router) { }

  ngOnInit() {
      this.fillerUid = JSON.parse(localStorage.getItem('currentUser')).user.uid;
      this.populateJobNumberList();
      this.jobNumsAvailable = [];
      
      //Init Work Order model
      this.model = <Form>{
              'id': '0',
              'type_id': this.typeId,
              'status_id': '1',
              'process_number': '1',
              'created_date': this.ssv.toHtmlDate(null),
              'last_modified_date': this.ssv.toHtmlDate(null),
              'filler_id': this.fillerUid,
              'modifier_id': this.fillerUid,
              'inputMap': {
                  'date': this.ssv.toHtmlDate(null)
              },
              "inputTables": {
                  "materials": [],
                  "labors": []
              }
          };
      
      //Init Stock Transfer model
      this.model2 = <Form>{
              'id': '0',
              'type_id': this.typeId2,
              'status_id': '1',
              'process_number': '1',
              'created_date': this.ssv.toHtmlDate(null),
              'last_modified_date': this.ssv.toHtmlDate(null),
              'filler_id': this.fillerUid,
              'modifier_id': this.fillerUid,
              'inputMap': {
                  'date': this.ssv.toHtmlDate(null)
              },
              "inputTables": {
                  "materials": []
              }
          };
  }
  
  onSubmit() {
      if(!this.jobNumsAvailable.includes(this.job_number)){
        //Create a new work order
          this.model.inputMap.job_num = this.job_number;
          this.fms.newForm(this.model, this.typeId)
          .subscribe(
              data => {
                  let woId = data['formId'];
                //If successful, create a new stock transfer
                  this.model2.inputMap.job_num = this.job_number;
                  this.fms.newForm(this.model2, this.typeId2)
                  .subscribe(
                      data => {
                            // Page redirect when getting response
                              this.ssv.showSuccess('Form successfully created!');
                            this.router.navigate(['/work-order/'+woId]);
                        },
                        error => {
                            // Display error message
                            this.ssv.showError('Unable to create stock transfer!');
                        }
                  );
                },
                error => {
                    // Display error message
                    this.ssv.showError('Unable to start job!');
                }
          );
          
      }
      else{
          this.ssv.showError('Error: job number taken!');
      }
    }

  populateJobNumberList() {
      //Get job numbers from non-deleted Work Orders (type = '45')
      this.fms.getForms(this.typeId, 1000, 'DESC', 0)
        .subscribe(WOList => {
            if(WOList){
                this.jobNumsAvailable = WOList.filter(x => x.is_deleted == '0').map(x => x.inputMap.job_num);
            }
        });
    }
}
