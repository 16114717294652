import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { User } from 'process-smart-ng6-lib';
import { UserIdleService } from 'angular-user-idle';
import { Router } from '@angular/router';
import { AuthenticationService } from 'process-smart-ng6-lib';
import { SharedService } from '../_services/shared.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, OnDestroy {
    session: any;

  constructor(private userIdle: UserIdleService, private router: Router, 
          private auth: AuthenticationService, public ssv: SharedService) {
  }

  ngOnInit() {
    // Check session. Redirect to login page if it doesn't exist
    if (!localStorage.getItem('currentUser')) {
        this.auth.logout();
        this.router.navigate(['/login']);
        return;
    }
    this.session = JSON.parse(localStorage.getItem('currentUser'));

      // Start watching for user inactivity.
    this.userIdle.startWatching();

    // Start watching when user idle is starting.
    this.userIdle.onTimerStart().subscribe((count) => {
        // if (!this.inactiveUser) {
        //     this.inactiveUser = true;
        //     this.popupTimeoutWarning();
        // }
    });

    // Logs out when time is up.
    this.userIdle.onTimeout().subscribe(() => {
        // remove user from local storage to log user out
        this.auth.logout();
        this.router.navigate(['/login']);
    });

    // Send a keepalive request every 10 minutes
    this.userIdle.ping$.subscribe(() => {
        // Check session. Redirect to login page if it doesn't exist
        if (localStorage.getItem('currentUser') === null) {
            this.auth.logout();
            this.router.navigate(['/login']);
        }
        // Keepalive using getrole() request
        this.auth.getRole(this.session.user.role_id).subscribe(role => {}, error => {
            this.auth.logout();
            this.router.navigate(['/login']);
        });
    });
    
    // Open default page based on role
    switch(this.session.user.role_id){
    //Admin
    case 1: this.router.navigate(['/view-employees']); break;
    //Management/VP (Tom), Maintenance Project manager
    case 2:
    case 3: this.router.navigate(['/view-walkthrough/2']); break;
    //Office Manager (Kalyn), Customer Service (Ayla)
    case 5:
    case 7: this.router.navigate(['/view-walkthrough/3']); break;
    //Irrigation Manager (Dan)
    case 4: this.router.navigate(['/view-irrigation-repair/2']); break;
    //Irrigation Technician
    case 6: this.router.navigate(['/view-irrigation-repair/1']); break;
    //Irrigation Staff (Jose Reyes)
    case 21: this.router.navigate(['/view-irrigation-repair/3']); break;
    //Purchasing Staff, Construction Office Staff
    case 8:
    case 11: this.router.navigate(['/view-purchase-order']); break;
    //Accounting Staff
    case 9: this.router.navigate(['/view-work-order']); break;
    //Construction PM
    case 10: this.router.navigate(['/view-c-work-order']); break;
    //Janitorial Manager
    case 12: this.router.navigate(['/view-materials/6']); break;
    //Porter
    case 13: this.router.navigate(['/view-stock-transfer/1']); break;
    }
  }

  ngOnDestroy() {
      this.userIdle.stopTimer();
      this.userIdle.stopWatching();
  }

  @HostListener('document:click')
  clicked() {
    this.userIdle.stopTimer();
  }

  get success() {
    return this.ssv.sharedNode.success;
  }

  get error() {
    return this.ssv.sharedNode.error;
  }
}
