import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from "@angular/common/http";
import { AuthenticationService } from "process-smart-ng6-lib";
import { Observable, throwError } from "rxjs";
import { Injectable } from "@angular/core";
import { retry, catchError } from "rxjs/operators";
import { Router } from "@angular/router";

@Injectable()
export class AuthTknInterceptor implements HttpInterceptor {
  constructor(private router: Router, public auth: AuthenticationService) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
      return next.handle(req).pipe(
              retry(2),
              catchError((error: HttpErrorResponse) => {
                if (error.status === 401) {
                  // 401 handled in auth.interceptor
                    this.auth.logout();
                    this.router.navigate(['/login']);
                }
                return throwError(error);
              })
            );
    }
}