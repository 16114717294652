import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { FormManagementService, AdministrationService, Customer, Form, Material, InventoryManagementService } from "process-smart-ng6-lib";
import { SharedService } from "src/app/_services/shared.service";
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-stock-transfer',
  templateUrl: './stock-transfer.component.html',
  styleUrls: ['./stock-transfer.component.css']
})
export class StockTransferComponent implements OnInit {
    formId: string;
    typeId = '46';
    model: Form;
    fillerUid: string;
    clientId: string;
    
    customersList: Array<Customer>; //List of all customers (for project name)
    custRecList: Array<Customer>; //List of filtered customers from search
    
    partsList: Array<Material>;     //List of all parts
    suggestedPartsList: Array<Material>;   //Filtered list of parts
    currFocus: number;  //Index of item currently being focused
    
    matList: Array<any>;    //List of used parts (with select properties)
    iosScanFlag = false;
    isDraft = true;
    
    constructor(private route: ActivatedRoute, private fms: FormManagementService,
            private ssv: SharedService, private adms: AdministrationService, 
            private ims: InventoryManagementService, private router: Router,
            private coos: CookieService) { }
      
      ngOnInit() {
          //Get user info from session
          this.fillerUid = JSON.parse(localStorage.getItem('currentUser')).user.uid;
          this.clientId = JSON.parse(localStorage.getItem('currentUser')).user.client_id;
          
          //Populate customers for search
          this.getPartsList();
          this.getCustomersList();
          
          //Get form ID if specified on route.
          //If specified, load the form.
        this.route.params.subscribe(params => {
              this.formId = params['formId'];
              
              if(this.formId){
                //Save form ID in cookie
                  //document.cookie = 'formId=' + this.formId + '; max-age=86400; path="/"';
                  this.coos.set('formId', this.formId, null, '/');
                  
                  //Load Form
                  this.getModel();
              }
              else {
                  //Create a new form
                  this.model = <Form>{
                      'id': this.formId,
                      'type_id': this.typeId,
                      'status_id': '1',
                      'process_number': '1',
                      'created_date': this.toHtmlDate(null),
                      'last_modified_date': this.toHtmlDate(null),
                      'filler_id': this.fillerUid,
                      'modifier_id': this.fillerUid,
                      'inputMap': {
                          "date": this.toHtmlDate(null)
                      },
                      "inputTables": {
                          "materials": []
                      }
                  };
                  this.matList = [];
              }
          });
      }
      
      getModel() {
          this.fms.getForm(this.typeId, this.formId)
            .subscribe(model => {
                model.created_date = this.toHtmlDate(model.created_date);
                model.last_modified_date = this.toHtmlDate(null);
                this.model = model;
                this.matList = model.inputTables.materials;
            });
      }
      
      onSubmit() {
        //Collect all materials and labor
          this.model.inputTables.materials = this.matList;
          
          //Get api key
          const apiKey = JSON.parse(localStorage.getItem('currentUser')).apiKey;
          const form_id = this.formId;  
          
          //Upload form data to DB
          if(this.formId){
              //Update an existing form
              this.fms.updateForm(this.model, this.typeId, this.formId)
              .subscribe(
                  data => {
                    //Save form ID in cookie
                      //document.cookie = 'formId=' + data['formId'] + '; max-age=86400; path="/"';
                      this.coos.set('formId', this.formId, null, '/');
                      
                      if(this.iosScanFlag){
                          this.iosScanFlag = false;
                          //console.log("Sending to: "+"scanpart://nissho/"+this.formId+"/"+apiKey);
                          
                          setTimeout(function(){document.location.href="scanpart://nissho/"+form_id+"/"+apiKey},250);
                      }
                      else{
                          this.ssv.showSuccess('Form successfully updated!');
                          // @ts-ignore
                         Android.reloadWebview();
                         
                         if(this.model.process_number == '2'){
                             this.router.navigate(['/view-stock-transfer/1']);
                         }
                      }
                    },
                    error => {
                        // Display error message
                        this.ssv.showError('Unable to update form!');
                    }
              );
          }
          else{
              //Create a new form
              this.fms.newForm(this.model, this.typeId)
              .subscribe(
                  data => {
                      //Save form ID in cookie
                      //document.cookie = 'formId=' + data['formId'] + '; max-age=86400; path="/"';
                      this.coos.set('formId', data['formId'], null, '/');
                      
                      this.formId = data['formId'];
                        // Page redirect when getting response
                      this.ssv.showSuccess('Form successfully updated!');
                        
                        if(this.iosScanFlag){
                            this.iosScanFlag = false;
                            //console.log("Sending to: "+"scanpart://nissho/"+data['formId']+"/"+apiKey);
                            setTimeout(function(){document.location.href="scanpart://nissho/"+data['formId']+"/"+apiKey},250);
                        }
                        else{
                             // @ts-ignore
                            Android.reloadWebview();
                            
                            if(this.model.process_number == '2'){
                                this.router.navigate(['/view-stock-transfer/1']);
                            }
                            else{
                                this.router.navigate(['/stock-transfer/'+data['formId']]);
                            }
                        }
                    
                    },
                    error => {
                        // Display error message
                        this.ssv.showError('Unable to update form!');
                    }
              );
          }
      }
      
      onSubmitProceed(){
        //Update process number if form is submitted (not just saved as draft)
          this.model.process_number = '2';
          this.onSubmit();
      }
      
      toHtmlDate(datestr : string){
          let date;
          if(datestr){
              date = new Date(datestr);
          }
          else{
              date = new Date();
          } 
          const dd = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
          const mm = date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
          const yyyy = date.getFullYear();
          
          return yyyy + '-' + mm + '-' + dd;
      }
      
    //MULTI INPUT FIELDS    
      addMaterial() {
          const mat : WO_Material = {'form_id': this.typeId,'material_id':'0'};
          this.matList.push(mat);
      }
      
      onDeleteMaterial(itemIdx : number){
          let deletedItem = this.matList[itemIdx];
          this.matList.splice(itemIdx, 1);
          this.suggestedPartsList = [];
          
          //Delete from server
          let inputMap = {"inputMap": deletedItem};
          this.fms.deleteMultiInput(inputMap, this.typeId, 'materials', 'material_id')
              .subscribe(data => {}, error => {console.log(error)});
      }
      
    //MATERIAL SEARCH
      getPartsList() {
          this.ims.getMaterials('6')
            .subscribe(list => {
                this.partsList = list;
            });
      }
      
      /**
       * When a suggestion is selected, assign part ID to matList at position == itemNum
       * Empty out suggestions for future use
       * @param itemNum
       */
      onSuggestionSelected(itemNum : number, matId, matName: string, price) {
          this.matList[itemNum].material_id = ""+matId;
          if(price){
              this.matList[itemNum].price = ""+price;
          }
          else{
              this.matList[itemNum].price = "0";
          }
          
          (<HTMLInputElement> document.getElementById('matname'+itemNum)).value = matName;
          this.suggestedPartsList = [];
      }
      
      searchItem(input: string, itemIdx: number) {
          this.currFocus = itemIdx;
          if (this.partsList) {
            this.suggestedPartsList = this.partsList.filter(x => 
                (x.description.toLowerCase().includes(input.toLowerCase())));
          }
        }
      
      getMaterialName(matId: string) : string {
          if(this.partsList){
              const part = this.partsList.find(x => x.id == matId);
              if(part){
                  return part.description;
              }
          }
          return '';
      }
      
      //CUSTOMER SEARCH
      getCustomersList() {
          this.adms.getCustomersByDept('6')
          .subscribe(list => {
              this.customersList = list;
          });
      }
      
      onCustomerSelected(custId: string) {
          const customer : Customer = this.customersList.find(x => x.id == custId);
          this.model.inputMap.name = customer.project_name;
          this.model.inputMap.job_num = customer.jobnum;
          this.custRecList = [];
      }
      
      searchCustomer(input: string) {
          if (this.customersList) {
            this.custRecList = this.customersList.filter(x => 
                (x.project_name.toLowerCase().includes(input.toLowerCase())));
          }
      }
      
      scanPart(){
          //Call Android Scanner function
        //Check if user is using iOS
          var userAgent = window.navigator.userAgent.toLowerCase(),
          safari = /safari/.test( userAgent ),
          ios = /iphone|ipod|ipad/.test( userAgent );
          
          if( ios ) {
              console.log('IS IOS');
              if ( safari ) {
                  //Safari browser
              } else if ( !safari ) {
                  //Webview
                  //Navigate to a fake URL
                  //setTimeout(function(){document.location.href="scanpart://nissho/irrigation"},250)
                  this.iosScanFlag = true;
                  this.onSubmit();
              };
          } else {
              this.onSubmit();
              //Call Android Scanner function
              console.log('Not IOS');
              // @ts-ignore
              Android.startScan();
          };
      }
}
