import { Component, OnInit } from '@angular/core';
import { Form, User } from "process-smart-ng6-lib";
import { FormManagementService, DocumentConversionService, AuthenticationService } from "process-smart-ng6-lib";
import { SharedService } from "src/app/_services/shared.service";
import { saveAs } from 'file-saver';
import { ActivatedRoute } from "@angular/router";
import { CustomDownloadServiceService } from "src/app/_services/custom-download-service.service";

@Component({
  selector: 'app-view-purchase-order',
  templateUrl: './view-purchase-order.component.html',
  styleUrls: ['./view-purchase-order.component.css']
})
export class ViewPurchaseOrderComponent implements OnInit {
    itemsList: Array<Form>;
    partialItemsList: Array<any>;
    usersList: Array<User>;
    userPermission: Array<any>;
    typeId = '48';
    processNum: string;
    paramJobnum: string;
    formProcessState: string;
    
    constructor(private fms: FormManagementService, private adms: AuthenticationService,
          private dcs: DocumentConversionService, private ssv: SharedService,
          private route: ActivatedRoute, private cds: CustomDownloadServiceService) { }
    
    ngOnInit() {
        this.populateNames();
        this.populatePermissions();
        
        this.route.params.subscribe(params => {
            this.processNum = params['processNum'];
            this.paramJobnum = params['jobnum'];
            this.populateItems();
            
            switch (this.processNum){
            case '1': this.formProcessState = 'Draft'; break;
            case '2': this.formProcessState = 'Waiting for Order'; break;
            case '3': this.formProcessState = 'Completed'; break;
            default: this.formProcessState = 'All'; break;
            }
        });
        
        const uid = JSON.parse(localStorage.getItem('currentUser')).user.uid;
    }
    
    populatePermissions() {
        const role_id = JSON.parse(localStorage.getItem('currentUser')).user.role_id;
        this.adms.getFormPermissions(role_id)
          .subscribe(list => this.userPermission = list.filter(x => x.form_type_id == this.typeId).map(x => x.process_num));
      }
    
    populateItems() {
    this.fms.getForms(this.typeId, 20, 'DESC', 0)
      .subscribe(itemsList => {
          if(this.processNum){
              itemsList = itemsList.filter(x => x.process_number == this.processNum);
          }
          
        //Only include non-deleted forms
          itemsList = itemsList.filter(x => x.is_deleted == '0');
          
        //If available, filter by job number
          if(this.paramJobnum){
              itemsList = itemsList.filter(x => x.inputMap.job_num == this.paramJobnum);
          }
          if(itemsList){
              this.itemsList = itemsList.sort((a, b) => {
                  return (a.id < b.id ? 1 : a.id > b.id ? -1 : 0);
              });
          }
          this.partialItemsList = itemsList;
      });
    }
    
    populateNames() {
      this.adms.getUsers()
        .subscribe(usersList => this.usersList = usersList);
    }
    
    getEmployeeName(uid: string) {
        if (uid == null || this.usersList == null) { return ''; }
        const user = this.usersList.find(x => x.uid === uid);
        return (user? user.name : '-');
    }
    
    onDownloadPDF(id: string) {
        //Disable download until response received
        this.partialItemsList.find(x => x.id == id).is_downloading_pdf = true;
        
      this.cds.downloadPurchaseOrderPDF(id)
          .subscribe(
              res => {
                  saveAs(res, 'PurchaseOrder.pdf', 
                      { type: 'application/pdf' });
                  //Re-enable download
                  this.partialItemsList.find(x => x.id == id).is_downloading_pdf = false;
              },
              error => {
                  // Display error message
                  this.ssv.showError('Error: Unable to download form!');
                  
                //Re-enable download
                  this.partialItemsList.find(x => x.id == id).is_downloading_pdf = false;
              }
          );
    }
    
    searchItem(input: string) {
      if (this.partialItemsList && this.itemsList) {
        this.partialItemsList = this.itemsList.filter(x => 
            (x.inputMap.job_num.toLowerCase().includes(input.toLowerCase())) ||
            x.inputMap.po_num.toLowerCase().includes(input.toLowerCase()));
      }
    }
}
