import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { SharedService } from "src/app/_services/shared.service";
import { FormManagementService, Form, InventoryManagementService, Material, AdministrationService, Customer } from "process-smart-ng6-lib";
import { S3Manager } from "src/app/api/S3Manager";
@Component({
  selector: 'app-m-work-order',
  templateUrl: './m-work-order.component.html',
  styleUrls: ['./m-work-order.component.css']
})
export class MWorkOrderComponent implements OnInit {
    formId: string;
    typeId = '51';
    model: Form;
    fillerUid: string;
    clientId: string;
    
    customersList: Array<Customer>; //List of all customers (for project name)
    custRecList: Array<Customer>; //List of filtered customers from search
    
    partsList: Array<Material>;     //List of all parts
    suggestedPartsList: Array<Material>;   //Filtered list of parts
    currFocus: number;  //Index of item currently being focused
    
    matList: Array<any>;    //List of used parts (with select properties)
    labList: Array<WO_Labor>;       //List of used labors
    
    attachment1: File;
    attachment2: File;
    
    total_cost: string;
    
    isDraft = true;
    
    constructor(private route: ActivatedRoute, private fms: FormManagementService,
          private ssv: SharedService, private ims: InventoryManagementService,
          private adms: AdministrationService, private router: Router) { }
    
    ngOnInit() {
        this.total_cost = '0';
        
        //Get user info from session
        this.fillerUid = JSON.parse(localStorage.getItem('currentUser')).user.uid;
        this.clientId = JSON.parse(localStorage.getItem('currentUser')).user.client_id;
        
        //Populate parts and customers for search
        this.getPartsList();
        this.getCustomersList();
        
        //Get form ID if specified on route.
        //If specified, load the form.
      this.route.params.subscribe(params => {
            this.formId = params['formId'];
            
            if(this.formId){
                //Load Form
                this.getModel();
            }
            else {
                //Create a new form
                this.model = <Form>{
                    'id': this.formId,
                    'type_id': this.typeId,
                    'status_id': '1',
                    'process_number': '1',
                    'created_date': this.toHtmlDate(null),
                    'last_modified_date': this.toHtmlDate(null),
                    'filler_id': this.fillerUid,
                    'modifier_id': this.fillerUid,
                    'inputMap': {
                    },
                    "inputTables": {
                        "materials": [],
                        "labors": []
                    }
                };
                this.matList = [];
                this.labList = [];
            }
        });
    }
    
    getModel() {
        this.fms.getForm(this.typeId, this.formId)
          .subscribe(model => {
              model.created_date = this.toHtmlDate(model.created_date);
              model.last_modified_date = this.toHtmlDate(null);
              this.model = model;
              this.matList = model.inputTables.materials;
              this.labList = model.inputTables.labors;
          });
    }
    
    getStockTransferTotalCost(){
        
    }
    
    onSubmit() {
      //Collect all materials and labor
      this.model.inputTables.materials = this.matList;
      this.model.inputTables.labors = this.labList;
      
      //Upload form data to DB
      if(this.formId){
          //Update an existing form
          this.fms.updateForm(this.model, this.typeId, this.formId)
          .subscribe(
              data => {
                  this.ssv.showSuccess('Form successfully updated!');
                  this.router.navigate(['/view-m-work-order/'+this.model.process_number]);
                },
                error => {
                    // Display error message
                    this.ssv.showError('Unable to update form!');
                }
          );
      }
      else{
          //Create a new form
          this.fms.newForm(this.model, this.typeId)
          .subscribe(
              data => {
                    // Page redirect when getting response
                  this.ssv.showSuccess('Form successfully updated!');
                    this.router.navigate(['/view-m-work-order/'+this.model.process_number]);
                },
                error => {
                    // Display error message
                    this.ssv.showError('Unable to update form!');
                }
          );
      }
      
      //Save attachments in S3
      for(let num = 1; num <= 2; num++) {
          if(this.model.inputMap['attachment'+num] && this['attachment'+num]){
              S3Manager.uploadFormAttachment(this['attachment'+num], this.model.inputMap['attachment'+num],this.clientId);
          }
      }
    }
    
    //Submit to Irrigation Manager
    onSubmitProceed(){
      //Update process number if form is submitted (not just saved as draft)
        this.model.process_number = '2';
        this.onSubmit();
      }
    
    onDownloadAttachment(num: string) {
      const filename = this.model.inputMap['attachment'+num];
      S3Manager.downloadFormAttachment(filename, this.clientId);
    }
    
    //Filename = form[GUID]_att[num].[ext]
    onFileAdded(files, num) {
      if (files === undefined || files.length == 0) {
          this['attachment'+num] = null;
          this.model['attachment'+num] = '';
      }
      else{
          const ext = files[0].name.substr(files[0].name.lastIndexOf('.'));
          this['attachment'+num] = files[0];
          this.model.inputMap['attachment'+num] = 'form'+this.newGuid()+'_att'+num+ext;
      }
    }
    
    //Create Unique ID
    newGuid() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
          const r = Math.random() * 16 | 0, v = c === 'x' ? r : ( r & 0x3 | 0x8 );
          return v.toString(16);
      });
    }
    
    toHtmlDate(datestr : string){
        let date;
        if(datestr){
            date = new Date(datestr);
        }
        else{
            date = new Date();
        } 
        const dd = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
        const mm = date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
        const yyyy = date.getFullYear();
        
        return yyyy + '-' + mm + '-' + dd;
    }
    
    //MULTI INPUT FIELDS    
    addMaterial() {
        const mat : WO_Material = {'form_id': this.formId,'material_id':'0'};
        this.matList.push(mat);
    }
    
    onDeleteMaterial(itemIdx : number){
        let deletedItem = this.matList[itemIdx];
        this.matList.splice(itemIdx, 1);
        this.suggestedPartsList = [];
        
        //Delete from server
        let inputMap = {"inputMap": deletedItem};
        this.fms.deleteMultiInput(inputMap, this.typeId, 'materials', 'material_id')
            .subscribe(data => {}, error => {console.log(error)});
    }
    
    addLabor() {
        const lab : WO_Labor = {'form_id': this.formId};
        this.labList.push(lab);
    }
    
    onDeleteLabor(itemIdx : number){
        this.labList.splice(itemIdx, 1);
    }
    
    //MATERIAL SEARCH
    getPartsList() {
        this.ims.getMaterials('1')
          .subscribe(list => {
              this.partsList = list;
          });
    }
    
    /**
     * When a suggestion is selected, assign part ID to matList at position == itemNum
     * Empty out suggestions for future use
     * @param itemNum
     */
    onSuggestionSelected(itemNum : number, matId, matName: string) {
        this.matList[itemNum].material_id = ""+matId;
        (<HTMLInputElement> document.getElementById('matname'+itemNum)).value = matName;
        this.suggestedPartsList = [];
    }
    
    searchItem(input: string, itemIdx: number) {
        this.currFocus = itemIdx;
        if (this.partsList) {
          this.suggestedPartsList = this.partsList.filter(x => 
              (x.description.toLowerCase().includes(input.toLowerCase())));
        }
      }
    
    getMaterialName(matId: string) : string {
        if(this.partsList){
            const part = this.partsList.find(x => x.id == matId);
            if(part){
                return part.description;
            }
        }
        return '';
    }
    
    //CUSTOMER SEARCH
    getCustomersList() {
        this.adms.getCustomersByDept('2')
        .subscribe(list => {
            this.customersList = list;
        });
    }
    
    onCustomerSelected(custId: string) {
        const customer : Customer = this.customersList.find(x => x.id == custId);
        this.model.inputMap.project_name = customer.project_name;
        this.model.inputMap.client_name = customer.contact;
        this.model.inputMap.client_phone = customer.phone;
        this.model.inputMap.client_email = customer.email;
        this.custRecList = [];
    }
    
    searchCustomer(input: string) {
        if (this.customersList) {
          this.custRecList = this.customersList.filter(x => 
              (x.project_name.toLowerCase().includes(input.toLowerCase())));
        }
      }
    
    //DIAGNOSTIC
    get diagnostic() { return JSON.stringify(this.model); }
    get mdiagnostic() { return JSON.stringify(this.matList); }
    get ldiagnostic() { return JSON.stringify(this.labList); }
}
