import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { AuthenticationService, Role } from "process-smart-ng6-lib";
import { SharedService } from "src/app/_services/shared.service";

@Component({
  selector: 'app-view-roles',
  templateUrl: './view-roles.component.html',
  styleUrls: ['./view-roles.component.css']
})
export class ViewRolesComponent implements OnInit {
    itemsList: Array<Role>;
    partialItemsList: Array<Role>;

    constructor(private adms: AuthenticationService, private ssv: SharedService, 
            private router: Router) { }
    
    ngOnInit() {
        //Authentication
        if(JSON.parse(localStorage.getItem('currentUser')).user.role_id != '1'){
            this.router.navigate(['/login']);
        }
        this.populateRoles();
    }

    populateRoles() {
        this.adms.getRoles()
          .subscribe(itemsList => {
              this.itemsList = itemsList;
              this.partialItemsList = itemsList;
          });
        }
    
    searchItem(input: string) {
        if (this.partialItemsList && this.itemsList) {
          this.partialItemsList = this.itemsList.filter(x => 
              (x.title.toLowerCase().includes(input.toLowerCase())));
        }
      }
}
