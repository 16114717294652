import { Component, OnInit } from '@angular/core';
import { EmailNotificationService, Email } from "process-smart-ng6-lib";
import { Router } from "@angular/router";
import { SharedService } from "src/app/_services/shared.service";

@Component({
  selector: 'app-bug-report',
  templateUrl: './bug-report.component.html',
  styleUrls: ['./bug-report.component.css']
})
export class BugReportComponent implements OnInit {

    bugDetails: string;
    user: any;
  constructor(private ems: EmailNotificationService, private ssv: SharedService,
          private router: Router) { }

  ngOnInit() {
      this.user = JSON.parse(localStorage.getItem('currentUser')).user;
  }

  onSubmit(){
    //Send Email
      const recipientList = ['ttchan@personitech.com'];
      const ccList = ['eangkasa@personitech.com'];
      const bccList = [];
      const subject = "Nissho Bug Report";
      const content = "I have encountered the following bug/error:<br><i>"
              + (!this.bugDetails ? "Unspecified" : this.bugDetails)
              + "</i><br><br><b>Sent by: </b>" + this.user.name
              + "<br><b>Phone: </b>" + this.user.phone
              + "<br><b>Email: </b>" + this.user.email;
      const email: Email = {'subject':subject,
                              'body':content,
                              'to': recipientList,
                              'cc':ccList,
                              'bcc':bccList};
      this.ems.sendEmail(email)
      .subscribe(data => {
          this.router.navigate(['/']);
          this.ssv.showSuccess('Bug Report successfully submitted!');
      },
      error => {
          this.router.navigate(['/']);
          this.ssv.showError('Failed to send bug report: Please re-login and try again');
      });
  }
}
