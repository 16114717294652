import { Component, OnInit } from '@angular/core';
import { Form, User } from "process-smart-ng6-lib";
import { FormManagementService, DocumentConversionService, AuthenticationService } from "process-smart-ng6-lib";
import { SharedService } from "src/app/_services/shared.service";
import { saveAs } from 'file-saver';
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-view-stock-transfer',
  templateUrl: './view-stock-transfer.component.html',
  styleUrls: ['./view-stock-transfer.component.css']
})
export class ViewStockTransferComponent implements OnInit {
    itemsList: Array<Form>;
    partialItemsList: Array<Form>;
    usersList: Array<User>;
    userPermission: Array<string>;  //Contains a list of process numbers this role is responsible for
    formId = '46';
    processNum: string;
    roleId: string;
    uid: string;
    
    constructor(private fms: FormManagementService, private adms: AuthenticationService,
          private dcs: DocumentConversionService, private ssv: SharedService,
          private route: ActivatedRoute) { }
    
    ngOnInit() {
        this.populatePermissions();
        this.populateNames();
        
        this.route.params.subscribe(params => {
            this.processNum = params['processNum'];
        });
        this.populateItems();
        
        this.uid = JSON.parse(localStorage.getItem('currentUser')).user.uid;
        this.roleId = JSON.parse(localStorage.getItem('currentUser')).user.role_id;
    }
    
    populateItems() {
    this.fms.getForms(this.formId, 50, 'DESC', 0)
      .subscribe(itemsList => {
          if(itemsList){
              if(this.processNum){
                  itemsList = itemsList.filter(x => x.process_number == this.processNum);
              }
              
              if(this.roleId == '13'){
                  itemsList = itemsList.filter(x => x.filler_id == this.uid);
              }
              
              this.itemsList = itemsList.sort((a, b) => {
                  return (a.id < b.id ? 1 : a.id > b.id ? -1 : 0);
              });
          }
          this.partialItemsList = itemsList;
      });
    }
    
    populateNames() {
      this.adms.getUsers()
        .subscribe(usersList => this.usersList = usersList);
    }
    
    populatePermissions() {
        const role_id = JSON.parse(localStorage.getItem('currentUser')).user.role_id;
        this.adms.getFormPermissions(role_id)
          .subscribe(list => this.userPermission = list.map(x => x.process_num));
      }
    
    getEmployeeName(uid: string) {
        if (uid == null || this.usersList == null) { return ''; }
        const user = this.usersList.find(x => x.uid === uid);
        return (user? user.name : '-');
    }
    
    onDownload(id: number) {
    //  this.dcs.DownloadWorkOrder(id)
    //      .subscribe(
    //          res => {
    //              saveAs(res, 'WorkOrder.xls', 
    //                  { type: 'application/vnd.ms-excel' })
    //          },
    //          error => {
    //              // Display error message
    //              this.ssv.showError('Error: Unable to download form!');
    //          }
    //      );
    }
    
    searchItem(input: string) {
      if (this.partialItemsList && this.itemsList) {
        this.partialItemsList = this.itemsList.filter(x => 
            (x.inputMap.job_num.toLowerCase().includes(input.toLowerCase())) ||
            x.inputMap.name.toLowerCase().includes(input.toLowerCase()));
      }
    }
}
