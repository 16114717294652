import { Component, OnInit } from '@angular/core';
import { AdministrationService, AuthenticationService } from "process-smart-ng6-lib";
import { CustomDownloadServiceService } from "src/app/_services/custom-download-service.service";
import { saveAs } from 'file-saver';
import { SharedService } from "src/app/_services/shared.service";

@Component({
  selector: 'app-view-data-usage',
  templateUrl: './view-data-usage.component.html',
  styleUrls: ['./view-data-usage.component.css']
})
export class ViewDataUsageComponent implements OnInit {

    curr_monthyear: any;
    keyList: Array<any>;
    itemsList: Array<any>;
    usersList: Array<any>;
    rolesList: Array<any>;
    
  constructor(private cds: CustomDownloadServiceService, private adms: AdministrationService,
          private ssv: SharedService, private aus: AuthenticationService) { }

  ngOnInit() {
      this.curr_monthyear = new Date();
      this.populateUsage();
      this.populateNames();
      this.populateRoles();
  }
  
  populateNames() {
      this.aus.getUsers()
        .subscribe(usersList => this.usersList = usersList);
    }
  
  populateRoles() {
      this.aus.getRoles()
        .subscribe(rolesList => this.rolesList = rolesList);
    }

  populateUsage(){
      this.adms.getDataUsage(1)
          .subscribe(itemsList => {
              let curStartOfMonth = this.ssv.toHtmlDate(null);
              curStartOfMonth = curStartOfMonth.substring(0,curStartOfMonth.lastIndexOf('-'))+'-01';
              this.keyList = Object.keys(itemsList[curStartOfMonth]);
              this.itemsList = itemsList[curStartOfMonth];
        },
        error => {
        });
  }
  
  onDownloadXls(months) {
      this.cds.downloadDataUsageXls(months)
          .subscribe(
              res => {
                  saveAs(res, 'DataUsage.xls', 
                      { type: 'application/vnd.ms-excel' })
              },
              error => {
                  // Display error message
                  this.ssv.showError('Error: Unable to download data usage!');
              }
          );
    }
  
  getName(id: string){
      const user = this.usersList.find(x => x.uid == id);
      if(user){
          return user.name;
      }
      return '-';
  }
  
  getRole(id: string){
      const user = this.usersList.find(x => x.uid == id);
      if(user){
          const role = this.rolesList.find(x => x.id == user.role_id);
          if(role){
              return role.title;
          }
      }
      return '-';
  }
}
