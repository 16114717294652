import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpResponse, HttpEvent, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { Configuration } from "process-smart-ng6-lib";

@Injectable({
  providedIn: 'root'
})
export class CustomDownloadServiceService {

    protected basePath = 'https://api.nisshoca.com:8443/ProcessSmart';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient) {
        this.defaultHeaders = this.defaultHeaders.set('Authorization', 'Bearer ' + JSON.parse(localStorage.getItem('currentUser')).apiKey);
    }
    
    /**
     * Download PDF of a Walkthru form (Punchlist)
     * @param id Form ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadWalkthroughPDF(id: string) {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling downloadFormPDF.');
        }
        
        this.defaultHeaders = this.defaultHeaders.set('Authorization', 'Bearer ' + JSON.parse(localStorage.getItem('currentUser')).apiKey);
        let headers = this.defaultHeaders;
        let params = new HttpParams();
        params = params.append('formId', id);

        // authentication (bearerAuth) required
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/pdf',
            'text/plain'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get(`${this.basePath}/DownloadWalkthroughPDF`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                params: params,
                responseType: 'blob'
            }
        );
    }
    
    /**
     * Download PDF of a Walkthru form (Punchlist)
     * @param id Form ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadWalkthroughDocx(id: string) {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling downloadFormPDF.');
        }
        
        this.defaultHeaders = this.defaultHeaders.set('Authorization', 'Bearer ' + JSON.parse(localStorage.getItem('currentUser')).apiKey);
        let headers = this.defaultHeaders;
        let params = new HttpParams();
        params = params.append('formId', id);

        // authentication (bearerAuth) required
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'text/plain'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get(`${this.basePath}/DownloadWalkthroughDocx`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                params: params,
                responseType: 'blob'
            }
        );
    }
    
    /**
     * Download Work Order PDF
     * @param id
     */
    public downloadWorkOrderPDF(id: string) {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling downloadFormPDF.');
        }
        
        this.defaultHeaders = this.defaultHeaders.set('Authorization', 'Bearer ' + JSON.parse(localStorage.getItem('currentUser')).apiKey);
        let headers = this.defaultHeaders;
        let params = new HttpParams();
        params = params.append('formId', id);

        // authentication (bearerAuth) required
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/pdf',
            'text/plain'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get(`${this.basePath}/DownloadWorkOrderPDF`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                params: params,
                responseType: 'blob'
            }
        );
    }
    
    /**
     * Download Purchase Order PDF
     * @param id
     */
    public downloadPurchaseOrderPDF(id: string) {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling downloadFormPDF.');
        }
        
        this.defaultHeaders = this.defaultHeaders.set('Authorization', 'Bearer ' + JSON.parse(localStorage.getItem('currentUser')).apiKey);
        let headers = this.defaultHeaders;
        let params = new HttpParams();
        params = params.append('formId', id);

        // authentication (bearerAuth) required
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/pdf',
            'text/plain'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get(`${this.basePath}/DownloadPurchaseOrderPDF`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                params: params,
                responseType: 'blob'
            }
        );
    }
    
    /**
     * Download Work Order Spreadsheet
     * @param id
     */
    public downloadWorkOrderXls(id: string) {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling downloadFormPDF.');
        }
        
        this.defaultHeaders = this.defaultHeaders.set('Authorization', 'Bearer ' + JSON.parse(localStorage.getItem('currentUser')).apiKey);
        let headers = this.defaultHeaders;
        let params = new HttpParams();
        params = params.append('formId', id);

        // authentication (bearerAuth) required
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/vnd.ms-excel',
            'text/plain'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get(`${this.basePath}/DownloadWorkOrderXls`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                params: params,
                responseType: 'blob'
            }
        );
    }
    
    /**
     * Download Stock Transfer Spreadsheet
     * @param id
     */
    public downloadStockTransferXls(id: string) {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling downloadFormPDF.');
        }
        
        this.defaultHeaders = this.defaultHeaders.set('Authorization', 'Bearer ' + JSON.parse(localStorage.getItem('currentUser')).apiKey);
        let headers = this.defaultHeaders;
        let params = new HttpParams();
        params = params.append('formId', id);

        // authentication (bearerAuth) required
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/vnd.ms-excel',
            'text/plain'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get(`${this.basePath}/DownloadStockTransferXls`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                params: params,
                responseType: 'blob'
            }
        );
    }
    
    /**
     * Download Irrigation Repair Form PDF
     * @param id
     */
    public downloadIrrigationRepairPDF(id: string) {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling downloadFormPDF.');
        }
        this.defaultHeaders = this.defaultHeaders.set('Authorization', 'Bearer ' + JSON.parse(localStorage.getItem('currentUser')).apiKey);
        let headers = this.defaultHeaders;
        let params = new HttpParams();
        params = params.append('formId', id);

        // authentication (bearerAuth) required
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/pdf',
            'text/plain'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get(`${this.basePath}/DownloadIrrigationRepairPDF`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                params: params,
                responseType: 'blob'
            }
        );
    }
    
    public downloadIrrigationRepairPDF_zip(idList) {
        if (idList === null || idList === undefined) {
            throw new Error('Required parameter id was null or undefined when calling downloadFormPDF.');
        }
        this.defaultHeaders = this.defaultHeaders.set('Authorization', 'Bearer ' + JSON.parse(localStorage.getItem('currentUser')).apiKey);
        let headers = this.defaultHeaders;
        let params = new HttpParams();
        params = params.append('formIds', idList.join());

        // authentication (bearerAuth) required
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/zip'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get(`${this.basePath}/DownloadIrrigationRepairPDFs`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                params: params,
                responseType: 'blob'
            }
        );
    }
    
    public downloadAllWithJobnum_zip(jobnum: string) {
        if (!jobnum) {
            throw new Error('Required parameter jobnum was null or undefined when calling downloadAllWithJobnum_zip.');
        }
        this.defaultHeaders = this.defaultHeaders.set('Authorization', 'Bearer ' + JSON.parse(localStorage.getItem('currentUser')).apiKey);
        let headers = this.defaultHeaders;
        let params = new HttpParams();
        params = params.append('jobnum', jobnum);

        // authentication (bearerAuth) required
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/zip'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get(`${this.basePath}/DownloadAllFilesWithJobnum`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                params: params,
                responseType: 'blob'
            }
        );
    }
    
    /**
     * Change a form's state (process number)
     * @param id Form ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setActiveAllFormWithJobnum(jobnum: string, isActive: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (!jobnum) {
            throw new Error('Required parameter type jobnum was null or undefined when calling setActiveAllFormWithJobnum.');
        }
        if (!isActive) {
            throw new Error('Required parameter isActive was null or undefined when calling setActiveAllFormWithJobnum.');
        }
        let params = new HttpParams();
        params = params.append('jobnum', jobnum);
        params = params.append('isActive', ''+isActive);
        
        this.defaultHeaders = this.defaultHeaders.set('Authorization', 'Bearer ' + JSON.parse(localStorage.getItem('currentUser')).apiKey);let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/plain'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post(`${this.basePath}/setActiveAllFormWithJobnum`, null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
                params: params
            }
        );
    }
    
    public downloadDataUsageXls(months: string) {
        if (months === null || months === undefined) {
            throw new Error('Required parameter months was null or undefined when calling downloadDataUsageXls.');
        }
        
        this.defaultHeaders = this.defaultHeaders.set('Authorization', 'Bearer ' + JSON.parse(localStorage.getItem('currentUser')).apiKey);
        let headers = this.defaultHeaders;
        let params = new HttpParams();
        params = params.append('months', months);

        // authentication (bearerAuth) required
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/pdf',
            'text/plain'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get(`${this.basePath}/GetDataUsageXls`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                params: params,
                responseType: 'blob'
            }
        );
    }
    
    public downloadStockTransferMaterialUsage(start_date: string, end_date: string, filler_id?: string) {
        if (start_date === null || start_date === undefined) {
            throw new Error('Required parameter start_date was null or undefined when calling downloadStockTransferMaterialUsage.');
        }
        
        if (end_date === null || end_date === undefined) {
            throw new Error('Required parameter end_date was null or undefined when calling downloadStockTransferMaterialUsage.');
        }
        
        this.defaultHeaders = this.defaultHeaders.set('Authorization', 'Bearer ' + JSON.parse(localStorage.getItem('currentUser')).apiKey);
        let headers = this.defaultHeaders;
        let params = new HttpParams();
        params = params.append('start_date', start_date);
        params = params.append('end_date', end_date); 
        if(filler_id){
            params = params.append('fillerId', filler_id);
        }

        // authentication (bearerAuth) required
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/vnd.ms-excel',
            'text/plain'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get(`${this.basePath}/GetStockTransferHistoryXls`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                params: params,
                responseType: 'blob'
            }
        );
    }
    
    public downloadStockTransferMonthlySummary(month: string) {
        this.defaultHeaders = this.defaultHeaders.set('Authorization', 'Bearer ' + JSON.parse(localStorage.getItem('currentUser')).apiKey);
        let headers = this.defaultHeaders;
        let params = new HttpParams();
        params = params.append('period', 'month');
        params = params.append('periodNum', month); 

        // authentication (bearerAuth) required
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/vnd.ms-excel',
            'text/plain'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get(`${this.basePath}/DownloadStockTransferSummary`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                params: params,
                responseType: 'blob'
            }
        );
    }
    
    public downloadCustomerListXls() {
        this.defaultHeaders = this.defaultHeaders.set('Authorization', 'Bearer ' + JSON.parse(localStorage.getItem('currentUser')).apiKey);
        let headers = this.defaultHeaders;
        let params = new HttpParams();

        // authentication (bearerAuth) required
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/vnd.ms-excel',
            'text/plain'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get(`${this.basePath}/DownloadCustomerList`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                params: params,
                responseType: 'blob'
            }
        );
    }
    
    public downloadMaterialsListXls(deptId: string) {
        this.defaultHeaders = this.defaultHeaders.set('Authorization', 'Bearer ' + JSON.parse(localStorage.getItem('currentUser')).apiKey);
        let headers = this.defaultHeaders;
        let params = new HttpParams();
        params = params.append('deptId', deptId);

        // authentication (bearerAuth) required
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/vnd.ms-excel',
            'text/plain'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get(`${this.basePath}/DownloadInventoryList`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                params: params,
                responseType: 'blob'
            }
        );
    }
    public getIRFHeaders(limit: string): Observable<any> {
        this.defaultHeaders = this.defaultHeaders.set('Authorization', 'Bearer ' + JSON.parse(localStorage.getItem('currentUser')).apiKey);
        let headers = this.defaultHeaders;
        let params = new HttpParams();
        params = params.append('limit', limit);

        // authentication (bearerAuth) required
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get(`${this.basePath}/forms/47/getIRFHeaders`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                params: params
            }
        );
    }
}
