import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login';
import { AuthGuard } from './_guards';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AppUpdatesComponent } from './general/app-updates/app-updates.component';
import { BugReportComponent } from './general/bug-report/bug-report.component';
import { ViewWorkOrderComponent } from "src/app/forms/view-work-order/view-work-order.component";
import { ViewStockTransferComponent } from "src/app/forms/view-stock-transfer/view-stock-transfer.component";
import { ViewIrrigationRepairComponent } from "src/app/forms/view-irrigation-repair/view-irrigation-repair.component";
import { ViewPurchaseOrderComponent } from "src/app/forms/view-purchase-order/view-purchase-order.component";
import { ViewWalkthroughComponent } from "src/app/forms/view-walkthrough/view-walkthrough.component";
import { ViewCostSummaryComponent } from "src/app/forms/view-cost-summary/view-cost-summary.component";
import { ViewMWorkOrderComponent } from "src/app/forms/view-m-work-order/view-m-work-order.component";
import { ViewCWorkOrderComponent } from "src/app/forms/view-c-work-order/view-c-work-order.component";
import { ViewMaterialsComponent } from "src/app/inventory/view-materials/view-materials.component";
import { ViewContainersComponent } from "src/app/inventory/view-containers/view-containers.component";
import { ViewCustomersComponent } from "src/app/customers/view-customers/view-customers.component";
import { WorkOrderComponent } from "src/app/forms/fill/work-order/work-order.component";
import { ViewEmployeesComponent } from "src/app/administration/view-employees/view-employees.component";
import { ViewRolesComponent } from "src/app/administration/view-roles/view-roles.component";
import { FormEmployeesComponent } from "src/app/administration/form-employees/form-employees.component";
import { FormRolesComponent } from "src/app/administration/form-roles/form-roles.component";
import { WalkthroughComponent } from "src/app/forms/fill/walkthrough/walkthrough.component";
import { IrrigationRepairComponent } from "src/app/forms/fill/irrigation-repair/irrigation-repair.component";
import { StockTransferComponent } from "src/app/forms/fill/stock-transfer/stock-transfer.component";
import { IrrigationRepairPartsComponent } from "src/app/forms/fill/irrigation-repair-parts/irrigation-repair-parts.component";
import { ViewStockTransferStatisticsComponent } from "src/app/view-stock-transfer-statistics/view-stock-transfer-statistics.component";
import { StartJobComponent } from "src/app/forms/fill/start-job/start-job.component";
import { UpdateConstantsComponent } from "src/app/general/update-constants/update-constants.component";
import { FormMaterialsComponent } from "src/app/inventory/form-materials/form-materials.component";
import { FormContainersComponent } from "src/app/inventory/form-containers/form-containers.component";
import { ViewTruckInventoryComponent } from "src/app/inventory/view-truck-inventory/view-truck-inventory.component";
import { FormTruckInventoryComponent } from "src/app/inventory/form-truck-inventory/form-truck-inventory.component";
import { FormCustomersComponent } from "src/app/customers/form-customers/form-customers.component";
import { ViewDataUsageComponent } from "src/app/general/view-data-usage/view-data-usage.component";
import { MWorkOrderComponent } from "src/app/forms/fill/m-work-order/m-work-order.component";
import { PurchaseOrderComponent } from "src/app/forms/fill/purchase-order/purchase-order.component";
import { ViewStockByTruckComponent } from "src/app/view-stock-by-truck/view-stock-by-truck.component";
import { UpdateCustomersListComponent } from "src/app/populate/update-customers-list/update-customers-list.component";

const appRoutes: Routes = [
   { path: 'login', component: LoginComponent },
    { path: '', component: DashboardComponent,
    children: [
        // Forms
        {path: 'view-work-order/:processNum', component: ViewWorkOrderComponent},
        {path: 'view-work-order', component: ViewWorkOrderComponent},
        {path: 'view-stock-transfer/:processNum', component: ViewStockTransferComponent},
        {path: 'view-stock-transfer', component: ViewStockTransferComponent},
        {path: 'view-irrigation-repair/:processNum/:jobnum', component: ViewIrrigationRepairComponent},
        {path: 'view-irrigation-repair/:processNum', component: ViewIrrigationRepairComponent},
        {path: 'view-irrigation-repair', component: ViewIrrigationRepairComponent},
        {path: 'view-purchase-order/:processNum/:jobnum', component: ViewPurchaseOrderComponent},
        {path: 'view-purchase-order/:processNum', component: ViewPurchaseOrderComponent},
        {path: 'view-purchase-order', component: ViewPurchaseOrderComponent},
        {path: 'view-walkthrough/:processNum', component: ViewWalkthroughComponent},
        {path: 'view-walkthrough', component: ViewWalkthroughComponent},
        {path: 'view-cost-summary/:processNum', component: ViewCostSummaryComponent},
        {path: 'view-cost-summary', component: ViewCostSummaryComponent},
        {path: 'view-m-work-order/:processNum', component: ViewMWorkOrderComponent},
        {path: 'view-m-work-order', component: ViewMWorkOrderComponent},
        {path: 'view-c-work-order/:processNum', component: ViewCWorkOrderComponent},
        {path: 'view-c-work-order', component: ViewCWorkOrderComponent},
        
        {path: 'work-order/:formId', component: WorkOrderComponent},
        {path: 'work-order', component: WorkOrderComponent},
        {path: 'start-job', component: StartJobComponent},
        {path: 'stock-transfer/jobnum/:jobnum', component: StockTransferComponent},
        {path: 'stock-transfer/:formId', component: StockTransferComponent},
        {path: 'stock-transfer', component: StockTransferComponent},
        {path: 'irrigation-repair/:formId', component: IrrigationRepairComponent},
        {path: 'irrigation-repair', component: IrrigationRepairComponent},
        {path: 'irrigation-repair-parts/:formId', component: IrrigationRepairPartsComponent},
        {path: 'purchase-order/:formId', component: PurchaseOrderComponent},
        {path: 'purchase-order', component: PurchaseOrderComponent},
        {path: 'walkthrough/:formId', component: WalkthroughComponent},
        {path: 'walkthrough', component: WalkthroughComponent},
        {path: 'cost-summary/:formId', component: ViewCostSummaryComponent},
        {path: 'cost-summary', component: ViewCostSummaryComponent},
        {path: 'm-work-order/:formId', component: MWorkOrderComponent},
        {path: 'm-work-order', component: MWorkOrderComponent},
        {path: 'c-work-order/:formId', component: ViewCWorkOrderComponent},
        {path: 'c-work-order', component: ViewCWorkOrderComponent},

        // Inventory
        {path: 'view-materials/:id', component: ViewMaterialsComponent},
        {path: 'view-materials', component: ViewMaterialsComponent},
        {path: 'view-containers/:id', component: ViewContainersComponent},
        {path: 'view-containers', component: ViewContainersComponent},
        {path: 'view-truck-inventory/:id', component: ViewTruckInventoryComponent},
        {path: 'view-truck-inventory', component: ViewTruckInventoryComponent},
        {path: 'stock-transfer-statistics', component: ViewStockTransferStatisticsComponent},
        
        {path: 'form-materials/:deptId/:id', component: FormMaterialsComponent},
        {path: 'form-materials/:deptId', component: FormMaterialsComponent},
        {path: 'form-containers/:deptId/:id', component: FormContainersComponent},
        {path: 'form-containers/:deptId', component: FormContainersComponent},
        {path: 'form-truck-inventory/:deptId/:id', component: FormTruckInventoryComponent},
        {path: 'form-truck-inventory/:deptId', component: FormTruckInventoryComponent},
        
        //Customers
        {path: 'view-customers/:deptId', component: ViewCustomersComponent},
        {path: 'form-customers/:deptId/:id', component: FormCustomersComponent},
        {path: 'form-customers/:deptId', component: FormCustomersComponent},
        
        //Admin
        {path: 'view-employees', component: ViewEmployeesComponent},
        {path: 'view-employees/:status', component: ViewEmployeesComponent},
        {path: 'form-employee', component: FormEmployeesComponent},
        {path: 'form-employee/:id', component: FormEmployeesComponent},
        
        // General
        {path: 'app-updates', component: AppUpdatesComponent},
        {path: 'bug-report', component: BugReportComponent},
        {path: 'view-data-usage', component: ViewDataUsageComponent},
        {path: 'view-monthly-stock', component: ViewStockByTruckComponent},
        {path: 'update-constants', component: UpdateConstantsComponent},
        {path: 'update-customers-list-csv', component: UpdateCustomersListComponent}
    ],
    canActivate: [AuthGuard]
    },
    // otherwise redirect to home page
    { path: '**', redirectTo: '' }
];

export const routing = RouterModule.forRoot(appRoutes);
