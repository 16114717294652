import { Component, OnInit } from '@angular/core';
import { Customer, Form, AdministrationService, InventoryManagementService, LoggingService, Material } from "process-smart-ng6-lib";
import { CustomDownloadServiceService } from "src/app/_services/custom-download-service.service";

@Component({
  selector: 'app-view-stock-transfer-statistics',
  templateUrl: './view-stock-transfer-statistics.component.html',
  styleUrls: ['./view-stock-transfer-statistics.component.css']
})
export class ViewStockTransferStatisticsComponent implements OnInit {

    partsList: Array<any>;
    projectList: Array<Customer>;
    typeId = '46';
    deptId = '6';
    usageHistory: Array<any>;
    totalCost: string;
    
  constructor(private ls: LoggingService, private adms: AdministrationService,
          private ims: InventoryManagementService, private cds: CustomDownloadServiceService) { }

  ngOnInit() {
      this.populateProjects();
      this.populateMaterials();
      this.totalCost = '0';
  }
  
  populateMaterials(){
      this.ims.getMaterials(this.deptId)
          .subscribe(itemsList => {
               this.partsList = itemsList;
          });
  }
  
  populateProjects(){
      this.adms.getCustomersByDept(this.deptId)
          .subscribe(itemsList => {
               this.projectList = itemsList;
          });
  }

  populateItems(jobNum: string) {
      this.ls.getJobUsageStatistics('6',jobNum)
        .subscribe(itemsList => {
            this.usageHistory = itemsList;
            let totalCost = 0;
            this.usageHistory.forEach((v,i,obj) => {
                let mat = this.partsList.find(x => x.id == v.item_num);
                if(mat){
                    v.part_num = mat.part_num;
                    v.description = mat.description;
                    v.unit = mat.unit;
                    v.price = mat.price;
                    v.total = +v.quantity * +mat.price;
                    totalCost += +v.total;
                }
            });
            this.totalCost = ""+totalCost;
        });
      }
}
