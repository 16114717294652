﻿import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { environment } from '../../environments/environment';

import { AuthenticationService } from '../_services';
import { SharedService } from "src/app/_services/shared.service";
import { CookieService } from "ngx-cookie-service";

@Component({templateUrl: 'login.component.html'})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    error = '';
    recaptchaStr = '';
    
    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private ssv: SharedService,
        private authenticationService: AuthenticationService,
        private coos: CookieService) {}

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            uname_login: ['', Validators.required],
            pass_login: ['', Validators.required]
        });

        // reset login status
        this.ssv.sharedNode.error = '';
        this.ssv.sharedNode.success = '';
        this.authenticationService.logout();

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }

    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

    onSubmit() {
        this.submitted = true;
        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }

        this.loading = true;
        this.authenticationService.login(this.f.uname_login.value, this.f.pass_login.value)
            .pipe(first())
            .subscribe(
                data => {
                    if(data === null){
                        this.error = 'Invalid Username or Password!';
                        this.loading = false;
                    }
                    else{
                        if(data.user.is_active == 1){
                            //Make sure current user is set
                            if (data && data.apiKey) {
                                // store user details and jwt token in local storage to keep user logged in between page refreshes
                                localStorage.setItem('currentUser', JSON.stringify(data));
                            }
                            
                            //Save apiKey in cookie
                            this.coos.set('NisshoApiKey', data.apiKey, null, '/');
                            this.loading = false;
                            this.router.navigate([this.returnUrl]);
                        }
                        else{
                            this.authenticationService.logout();
                            this.error = 'Invalid Username or Password!';
                            this.loading = false;
                        }
                    }
                },
                error => {
                    this.error = 'Invalid Username or Password!';
                    this.loading = false;
                });
    }
    
    onLoginClick(): void {
//        if(environment.production === true){
//            if (this.recaptchaStr) {
//                captchaRef.reset();
//            }
//            captchaRef.execute();
//        }
//        else{
//            this.onSubmit();
//        }
        this.onSubmit();
    }
 
    public resolved(captchaResponse: string): void {
        this.recaptchaStr = captchaResponse;
        if (this.recaptchaStr) {
            this.onSubmit();
        }
    }
}
