import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { SharedService } from "src/app/_services/shared.service";
import { FormManagementService, Form, InventoryManagementService, Material, AdministrationService, Customer, AuthenticationService } from "process-smart-ng6-lib";
import { S3Manager } from "src/app/api/S3Manager";

@Component({
  selector: 'app-work-order',
  templateUrl: './work-order.component.html',
  styleUrls: ['./work-order.component.css']
})
export class WorkOrderComponent implements OnInit {
    formId: string;
    typeId = '45';
    model: Form;
    fillerUid: string;
    clientId: string;
    job_number: string;
    
    //Search
    customersList: Array<Customer>; //List of all customers (for project name)
    custRecList: Array<Customer>; //List of filtered customers from search
    
    pmList: Array<string>; //List of all PM name
    pmRecList: Array<string>; //List of all PM name
    
    partsList: Array<Material>;     //List of all parts
    suggestedPartsList: Array<Material>;   //Filtered list of parts
    currFocus: number;  //Index of item currently being focused
    
    matList: Array<any>;    //List of used parts (with select properties)
    labList: Array<WO_Labor>;       //List of used labors
    
    attachment1: File;
    attachment2: File;
    
    total_mat_cost: number;
    tech_hours: number;
    labor_hours: number;
    ot_hours: number;
    total_lab_cost: number;
    constants: any;
    
    stockTransfer: Form;     //...with the same job number
    repairFormList: Array<Form>;        //...with the same job number
    
    isDraft = true;
    
    constructor(private route: ActivatedRoute, private fms: FormManagementService,
          private ssv: SharedService, private ims: InventoryManagementService,
          private adms: AdministrationService, private aus: AuthenticationService,
          private router: Router) { }
    
    ngOnInit() {
        this.total_mat_cost = 0.0;
        this.total_lab_cost = 0.0;
        this.tech_hours = 2.0;
        this.ot_hours = 0.0;
        this.labor_hours = 0.0;
        
        this.repairFormList = [];
        
        //Get user info from session
        this.fillerUid = JSON.parse(localStorage.getItem('currentUser')).user.uid;
        this.clientId = JSON.parse(localStorage.getItem('currentUser')).user.client_id;
        
        //Populate parts and customers for search
        this.getConstants();
        this.getCustomersList();
        this.getPMList();
        
        //Get form ID if specified on route.
        //If specified, load the form.
      this.route.params.subscribe(params => {
            this.formId = params['formId'];
            
            if(this.formId){
                //Load Form
                this.getModel();
            }
        });
    }
    
    getModel() {
        this.fms.getForm(this.typeId, this.formId)
          .subscribe(model => {
              model.created_date = this.toHtmlDate(model.created_date);
              model.last_modified_date = this.toHtmlDate(null);
              this.model = model;
              this.matList = model.inputTables.materials;
              this.labList = model.inputTables.labors;
              this.job_number = model.inputMap.job_num;
              
              if(!model.inputMap.work_description){
                  this.model.inputMap.work_description = "Technician was dispatched to above location for ";
              }
              
              //Populate Forms
              this.populateST(model.inputMap.job_num);
              this.populateIRFs(model.inputMap.job_num);
          });
    }
    
    populateST(jobnum: string){
        this.fms.getForms('54', 1000, 'DESC', 0)
            .subscribe(list => {
                this.stockTransfer = list.find(x => x.is_deleted == '0' && x.inputMap.job_num == jobnum);
            });
    }
    
    populateIRFs(jobnum: string){
        this.fms.getForms('47', 1000, 'DESC', 0)
            .subscribe(list => {
                this.repairFormList = list.filter(x => x.is_deleted == '0' && x.inputMap.job_num == jobnum);
                this.setIrrigationRepairTotalLaborHrs();
            });
    }
    
    //TOTAL LABOR HOURS
    setIrrigationRepairTotalLaborHrs(){
        this.tech_hours = this.repairFormList.reduce((sum, irf) => 
        sum + (+irf.inputMap.tech_hours), 0);
        this.labor_hours = this.repairFormList.reduce((sum, irf) => 
        sum + (+irf.inputMap.labor_hours), 0);
        this.ot_hours = this.repairFormList.reduce((sum, irf) => 
        sum + (+irf.inputMap.ot_hours), 0);
        
        if(this.tech_hours < 2.0){
            this.tech_hours = 2;
        }
        
        this.tech_hours += Math.floor(this.tech_hours/2)*0.25;
        this.labor_hours += Math.floor(this.labor_hours/2)*0.25;
        this.ot_hours += Math.floor(this.ot_hours/2)*0.25;
        
        this.total_lab_cost = (this.tech_hours * this.constants.tech_rate) + 
            (this.labor_hours* this.constants.tech_labor_rate) + 
            (this.ot_hours * this.constants.tech_ot_rate);
    }
    
    //TOTAL MATERIAL COST
    getStockTransferTotalCost(){
        if(this.total_mat_cost){
            return this.total_mat_cost;
        }
        else if(this.stockTransfer && this.stockTransfer.inputTables && this.stockTransfer.inputTables.materials){
            //Add up all part qty * price each, add tax on top of them and apply markup
            this.total_mat_cost = this.stockTransfer.inputTables.materials.reduce((sum, mat) => 
            sum + (+mat.quantity * +mat.price) + ((+mat.quantity * +mat.price)*this.constants.tax_rate/100), 0);
            
            //Apply markup
            this.total_mat_cost = this.total_mat_cost * 100 / this.constants.markup_rate;
            return this.total_mat_cost;
        }
        else {
            return 0;
        }
    }
    
    getConstants(){
        this.adms.getConstants()
            .subscribe(data => {
               this.constants = data; 
            },
            error => {
                this.ssv.showError('Error: Failed to retrieve pricing rates!');
            });
    }
    
    onSubmit() {
      //Collect all materials and labor
      this.model.inputTables.materials = this.matList;
      this.model.inputTables.labors = this.labList;
      
      //Form validation
      if(!this.validateForm()){
          return;
      }
      
      //Upload form data to DB
      if(this.formId){
          //Update an existing form
          this.fms.updateForm(this.model, this.typeId, this.formId)
          .subscribe(
              data => {
                  this.ssv.showSuccess('Form successfully updated!');
                  this.router.navigate(['/view-work-order/'+this.model.process_number]);
                },
                error => {
                    // Display error message
                    this.ssv.showError('Unable to update form!');
                }
          );
      }
      else{
          //Create a new form
          this.model.inputMap.date = this.toHtmlDate(null);
          this.fms.newForm(this.model, this.typeId)
          .subscribe(
              data => {
                    // Page redirect when getting response
                  this.ssv.showSuccess('Form successfully updated!');
                    this.router.navigate(['/view-work-order/'+this.model.process_number]);
                },
                error => {
                    // Display error message
                    this.ssv.showError('Unable to create new form!');
                }
          );
      }
      
      //Save attachments in S3
      for(let num = 1; num <= 2; num++) {
          if(this.model.inputMap['attachment'+num] && this['attachment'+num]){
              S3Manager.uploadFormAttachment(this['attachment'+num], this.model.inputMap['attachment'+num],this.clientId);
          }
      }
    }
    
  //Submit to Irrigation Manager
    onSubmitProceed(){
      //Update process number if form is submitted (not just saved as draft)
        this.model.process_number = '2';
        this.onSubmit();
      }
    
    //Deactivate work order form and all activeIRFs, STs and POs with the same job number
    onDelete(){
        console.log('here');
        this.fms.setFormDeactivateStatus(this.typeId, this.formId, true)
            .subscribe(data => {
                //Deactivate IRFs w/ the same jobnum
                this.deactivateIRFs();
                
                //Deactivate ST w/ the same jobnum
                this.deactivateSTs();
                
                //Deactivate POs w/ the same jobnum
                //this.deactivatePOs();
                
                this.ssv.showSuccess("Work Order successfully deleted");
                this.router.navigate(['/view-work-order/x']);
            },
            error => {
                this.ssv.showError("Form(s) failed to be deleted");
                console.log(error);
            });
    }
    
    validateForm(): boolean{
        if(this.exceedChar(this.model.inputMap.po_num,50)){
            this.ssv.showError("IWO# cannot exceed 50 characters!");
            return false;
        }
        if(this.exceedChar(this.model.inputMap.project_name,100)){
            this.ssv.showError("Project Name cannot exceed 100 characters!");
            return false;
        }
        if(this.exceedChar(this.model.inputMap.client_name,100)){
            this.ssv.showError("Client Name cannot exceed 100 characters!");
            return false;
        }
        if(this.exceedChar(this.model.inputMap.client_phone,100)){
            this.ssv.showError("Client Phone cannot exceed 50 characters!");
            return false;
        }
        if(this.exceedChar(this.model.inputMap.client_email,100)){
            this.ssv.showError("Client Email cannot exceed 100 characters!");
            return false;
        }
        if(this.exceedChar(this.model.inputMap.auth_by,100)){
            this.ssv.showError("Authorized By cannot exceed 100 characters!");
            return false;
        }
        if(this.exceedChar(this.model.inputMap.work_description,3000)){
            this.ssv.showError("Work Description cannot exceed 3000 characters!");
            return false;
        }
        return true;
    }
    
    exceedChar(s: string, n: number): boolean{
        if(!s){
            return false;
        }
        return s.length > n;
    }
    
    deactivateIRFs(){
        //Get IDs of all IRFs with this job number
        this.repairFormList.forEach(x => {
            this.fms.setFormDeactivateStatus(x.type_id, x.id, true)
            .subscribe(data => {
                console.log('Deleted IRF '+ x.id);
            },
            error => {
                console.log(error);
            });
        });
    }
    
    deactivateSTs(){
        //Get ID of Stock Transfer with this job number
        this.fms.setFormDeactivateStatus(this.stockTransfer.type_id, this.stockTransfer.id, true)
        .subscribe(data => {
            console.log('Deleted ST '+ this.stockTransfer.id);
        },
        error => {
            console.log(error);
        });
    }
    
    onDownloadAttachment(num: string) {
      const filename = this.model.inputMap['attachment'+num];
      S3Manager.downloadFormAttachment(filename, this.clientId);
    }
    
    //Filename = form[GUID]_att[num].[ext]
    onFileAdded(files, num) {
      if (files === undefined || files.length == 0) {
          this['attachment'+num] = null;
          this.model.inputMap['attachment'+num] = '';
      }
      else{
          const ext = files[0].name.substr(files[0].name.lastIndexOf('.'));
          this['attachment'+num] = files[0];
          this.model.inputMap['attachment'+num] = 'form'+this.newGuid()+'_att'+num+ext;
      }
    }
    
    //Create Unique ID
    newGuid() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
          const r = Math.random() * 16 | 0, v = c === 'x' ? r : ( r & 0x3 | 0x8 );
          return v.toString(16);
      });
    }
    
    toHtmlDate(datestr : string){
        let date;
        if(datestr){
            date = new Date(datestr);
        }
        else{
            date = new Date();
        } 
        const dd = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
        const mm = date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
        const yyyy = date.getFullYear();
        
        return yyyy + '-' + mm + '-' + dd;
    }
    
  //PM SEARCH
    getPMList() {
        this.aus.getUsers()
          .subscribe(list => {
              if(list){
                  this.pmList = list.filter(x => x.role_id == '2' || x.role_id == '3')
                                      .map(x => x.name);
              }
          });
    }
    
    onPMSelected(pmName: string) {
        this.model.inputMap.project_manager = pmName;
        this.pmRecList = [];
    }
    
    searchPM(input: string) {
        if (this.pmList) {
          this.pmRecList = this.pmList.filter(x => 
              (x.toLowerCase().includes(input.toLowerCase())));
        }
      }
    
    //CUSTOMER SEARCH
    getCustomersList() {
        this.adms.getCustomers()
        .subscribe(list => {
            this.customersList = list;
        });
    }
    
    onCustomerSelected(custId: string) {
        const customer : Customer = this.customersList.find(x => x.id == custId);
        this.model.inputMap.project_name = customer.project_name;
        this.model.inputMap.client_name = customer.management;
        this.model.inputMap.client_phone = customer.phone;
        this.model.inputMap.client_email = customer.email;
        this.model.inputMap.auth_by = customer.contact;
        this.model.inputMap.project_manager = customer.project_manager;
        this.custRecList = [];
    }
    
    searchCustomer(input: string) {
        if (this.customersList) {
          this.custRecList = this.customersList.filter(x => 
              (x.project_name.toLowerCase().includes(input.toLowerCase())));
        }
      }
    
    //DIAGNOSTIC
    get diagnostic() { return JSON.stringify(this.model); }
    get mdiagnostic() { return JSON.stringify(this.matList); }
    get ldiagnostic() { return JSON.stringify(this.labList); }
}
