import { Component, OnInit } from '@angular/core';
import { Form, User } from "process-smart-ng6-lib";
import { FormManagementService, DocumentConversionService, AuthenticationService } from "process-smart-ng6-lib";
import { SharedService } from "src/app/_services/shared.service";
import { saveAs } from 'file-saver';
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";

@Component({
  selector: 'app-view-m-work-order',
  templateUrl: './view-m-work-order.component.html',
  styleUrls: ['./view-m-work-order.component.css']
})
export class ViewMWorkOrderComponent implements OnInit {
    itemsList: Array<Form>;
    partialItemsList: Array<Form>;

userPermission: Array<string>;
    usersList: Array<User>;
    typeId = '51';
    processNum: string;
    formProcessState: string;
    uid: string;
    roleId: string;
    clientId: string;
    reject_reason:string;
    focusedId: string;
    
    constructor(private fms: FormManagementService, private adms: AuthenticationService,
          private dcs: DocumentConversionService, private ssv: SharedService,
          private route: ActivatedRoute, private router: Router,) { }
    
    ngOnInit() {
        this.uid = JSON.parse(localStorage.getItem('currentUser')).user.uid;
        this.roleId = JSON.parse(localStorage.getItem('currentUser')).user.role_id;
        this.clientId = JSON.parse(localStorage.getItem('currentUser')).user.client_id;
        
        this.populateNames();
        this.populatePermissions();
        
        this.route.params.subscribe(params => {
            this.processNum = params['processNum'];
            this.populateItems();
            switch (this.processNum){
            case '1': this.formProcessState = 'Draft'; break;
            case '2': this.formProcessState = 'Pending Management Approval'; break;
            case '3': this.formProcessState = 'Awaiting Job Number'; break;
            case '4': this.formProcessState = 'Awaiting Job Assignment'; break;
            case '5': this.formProcessState = 'Active Job'; break;
            case '6': this.formProcessState = 'Ready for Billing'; break;
            case '7': this.formProcessState = 'Pending for Archival'; break;
            default: this.formProcessState = 'All'; break;
            }
        });
    }
    
    populatePermissions() {
        const role_id = JSON.parse(localStorage.getItem('currentUser')).user.role_id;
        this.adms.getFormPermissions(role_id)
          .subscribe(list => this.userPermission = list.map(x => x.process_num));
      }
    
    /**
     * Reject form and send it back to Customer Service for submission (process #1)
     * @param id
     */
    onReject() {
        let model: Form = this.itemsList.find(x => x.id == this.focusedId);
    
        //Change form state
        this.onSubmit('1', this.focusedId, 'rejected');
        
      //Send email to Technician
        const recipient = this.usersList.find(x => x.uid == model.filler_id);
        
        //TODO: Email sender
      }
    
    onApprove(){
        let model: Form = this.itemsList.find(x => x.id == this.focusedId);
    
        //Change form state
        this.onSubmit('3', this.focusedId, 'approved');
        
        //Send email to Customer Service (role = 7)
        const recipient = this.usersList.find(x => x.role_id == '7' && x.is_active == '1');
        
      //TODO: Email sender
    }
    
    onClickForm(id){
        this.focusedId = id;
    }
    
    onSubmit(state, id, actionVerb) {
        //Close all modals
        document.getElementById('approveIRF').style.display = "none";
        document.getElementById('deleteIRFModal').style.display = "none";
        
        //Update form
        this.fms.setFormState(this.typeId, id, state)
        .subscribe(
            data => {
                this.ssv.showSuccess('Form successfully '+actionVerb+'!');
                this.router.navigate(['/view-work-order/'+state]);
              },
              error => {
                  // Display error message
                  this.ssv.showError('Error: Form failed to be '+actionVerb+'!');
              }
        );
      }
    
    populateItems() {
    this.fms.getForms(this.typeId, 50, 'DESC', 0)
      .subscribe(itemsList => {
          if(this.processNum){
              itemsList = itemsList.filter(x => x.process_number == this.processNum);
          }
          
          //Exclude deleted forms
          itemsList = itemsList.filter(x => x.is_deleted == '0');
          
        //If this is drafts list or user is PM, only allow owner to see
          if(this.processNum == '1' || this.roleId == '3'){
              itemsList = itemsList.filter(x => x.filler_id == this.uid);
          }
          
          if(itemsList){
              this.itemsList = itemsList.sort((a, b) => {
                  return (a.id < b.id ? 1 : a.id > b.id ? -1 : 0);
              });
          }
          this.partialItemsList = itemsList;
      });
    }
    
    populateNames() {
      this.adms.getUsers()
        .subscribe(usersList => this.usersList = usersList);
    }
    
    getEmployeeName(uid: string) {
        if (uid == null || this.usersList == null) { return ''; }
        const user = this.usersList.find(x => x.uid === uid);
        return (user? user.name : '-');
    }
    
    onDownload(id: number) {
    //  this.dcs.DownloadWorkOrder(id)
    //  .subscribe(
    //      res => {
    //          saveAs(res, 'WorkOrder.xls', 
    //              { type: 'application/vnd.ms-excel' })
    //      },
    //      error => {
    //          // Display error message
    //          this.ssv.showError('Error: Unable to download form!');
    //      }
    //  );
    }
    
    searchItem(input: string) {
      if (this.partialItemsList && this.itemsList) {
        this.partialItemsList = this.itemsList.filter(x => 
            (x.inputMap.job_num.toLowerCase().includes(input.toLowerCase())) ||
            x.inputMap.project_name.toLowerCase().includes(input.toLowerCase()));
      }
    }
}
