import { Component, OnInit } from '@angular/core';
import { InventoryManagementService, Customer, AdministrationService } from "process-smart-ng6-lib";
import { ActivatedRoute } from "@angular/router";
import { SharedService } from "src/app/_services/shared.service";
import { Router } from "@angular/router";

@Component({
  selector: 'app-form-customers',
  templateUrl: './form-customers.component.html',
  styleUrls: ['./form-customers.component.css']
})
export class FormCustomersComponent implements OnInit {
    model: any;
    deptId: string;
    fillerUid: string;
    customerId: string;
    
    constructor(private route: ActivatedRoute,
            private router: Router, private ssv: SharedService,
            private adms: AdministrationService) { }
    
    ngOnInit() {
        this.fillerUid = JSON.parse(localStorage.getItem('currentUser')).user.uid;
        let clientId = JSON.parse(localStorage.getItem('currentUser')).user.client_id;
        
        //Load or create new form
        this.route.params.subscribe(params => {
            this.deptId = params['deptId'];
            this.customerId = params['id'];
            
            if(this.customerId){
                //Load Customer detail
                this.getModel();
            }
            else {
                //Create a new form
                this.model = {
                    department_id: this.deptId,
                    client_id: clientId
                };
            }
        });
    }
    
    DeleteCustomer(){
        if(!this.customerId)
            return;
        
        this.adms.deleteCustomer(this.customerId)
        .subscribe(
        data => {
            this.ssv.showSuccess('Client successfully deleted!');
              this.router.navigate(['/view-customers/' + this.deptId]);
          },
          error => {
              // Display error message
              this.ssv.showError('Unable to delete Client!');
              console.log(error);
          }
    );
    }
    
    onSubmit() {
      //Update DB if exists, or create a new one otherwise
        if(this.customerId){
            this.adms.updateCustomer(this.model, this.customerId)
            .subscribe(
            data => {
                this.ssv.showSuccess('Client successfully updated!');
                  this.router.navigate(['/view-customers/' + this.deptId]);
              },
              error => {
                  // Display error message
                  this.ssv.showError('Unable to update Client!');
                  console.log(error);
              }
        );
        }
        else{
            this.adms.newCustomer(this.model)
                .subscribe(
                data => {
                      // Page redirect when getting response
                    this.ssv.showSuccess('Client successfully added!');
                      this.router.navigate(['/view-customers/' + this.deptId]);
                  },
                  error => {
                      // Display error message
                      this.ssv.showError('Unable to add new client!');
                      console.log(error);
                  }
            );
        }
    }
    
    getModel(){
        this.adms.getCustomer(this.customerId)
        .subscribe(model => this.model = model);
    }
    
    get diagnostic() { return JSON.stringify(this.model); }
}
