import { Injectable } from '@angular/core';
import { FormManagementService } from "process-smart-ng6-lib";
import { Router } from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class SharedService {

    admin_role_id = '1';
    
    sharedNode = {
      success: '',
      error: ''
    };
    
    num_uploads = 0;
    redirect_to = '/';
    
    alerts = {
        wt_2: 0,
        wt_3: 0,
        irf_1: 0,
        irf_2: 0,
        irf_3: 0
    };

    uploadModalActive='none';
    
  constructor(private router: Router, private fms: FormManagementService) { }
  
  activateUploadModal(){
      this.uploadModalActive = 'block';
  }
  
  closeUploadModal(){
      this.uploadModalActive = 'none';
  }
  
  setRedirect(destination: string){
      this.redirect_to = destination;
  }
  
  redirect(){
      this.router.navigate([this.redirect_to]);
  }
  
  addUploadCount(){
      this.num_uploads++;
  }
  
  reduceUploadCount(){
      this.num_uploads--;
  }
  
  showSuccess(message: string){
      this.sharedNode.success = message;
      if(document.getElementById('suc_msg')){
          document.getElementById('suc_msg').style.display = "block";
      }
  }
  
  showError(message: string){
      this.sharedNode.error = message;
      if(document.getElementById('err_msg')){
          document.getElementById('err_msg').style.display = "block";
      }
  }
  
  toHtmlDate(datestr : string){
      let date;
      if(datestr){
          date = new Date(datestr);
      }
      else{
          date = new Date();
      } 
      const dd = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      const mm = date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
      const yyyy = date.getFullYear();
      
      return yyyy + '-' + mm + '-' + dd;
  }
  
  toHtmlDateStr(date : Date){
      const dd = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      const mm = date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
      const yyyy = date.getFullYear();
      
      return yyyy + '-' + mm + '-' + dd;
  }
  
//Create Unique ID
  newGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        const r = Math.random() * 16 | 0, v = c === 'x' ? r : ( r & 0x3 | 0x8 );
        return v.toString(16);
    });
  }
  
  //Count number of forms up to 1 year ago
  countForm(varName, typeId, filler_id, process_num){
      //Start date is a year ago
      let start_date = new Date();
      start_date.setDate(start_date.getDate() - 365);
      
      //End date is a year in the future
      let end_date = new Date();
      end_date.setDate(end_date.getDate() + 365);
      
      this.fms.getFormCount(typeId,this.toHtmlDateStr(start_date),this.toHtmlDateStr(end_date),filler_id,process_num)
          .subscribe(data => {
              this.alerts[varName] = +data.count;
          })
  }
}
