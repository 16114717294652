import { Component, OnInit } from '@angular/core';
import { Container, User } from "process-smart-ng6-lib";
import { InventoryManagementService, DocumentConversionService, AuthenticationService } from "process-smart-ng6-lib";
import { SharedService } from "src/app/_services/shared.service";
import { saveAs } from 'file-saver';
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-view-containers',
  templateUrl: './view-containers.component.html',
  styleUrls: ['./view-containers.component.css']
})
export class ViewContainersComponent implements OnInit {
    itemsList: Array<Container>;
    partialItemsList: Array<Container>;
    usersList: Array<User>;
    deptId: string;
    constructor(private ims: InventoryManagementService, private adms: AuthenticationService,
          private dcs: DocumentConversionService, private ssv: SharedService,
          private route: ActivatedRoute) { }
    
    ngOnInit() {
        this.route.params.subscribe(params => {
            this.deptId = params['id'];
            if(this.deptId){
                this.populateItems();
                this.populateNames();
            }
        });
    }
    
    populateItems() {
    this.ims.getContainers(this.deptId)
      .subscribe(itemsList => {
          this.itemsList = itemsList;
          this.partialItemsList = itemsList;
      });
    }
    
    populateNames() {
        this.adms.getUsers()
          .subscribe(usersList => this.usersList = usersList);
      }
      
      getEmployeeName(uid: string) {
          if (uid == null || this.usersList == null) { return ''; }
          const user = this.usersList.find(x => x.uid === uid);
          return (user? user.name : '-');
      }
    
    onDownload(id: number) {
    }
    
    searchItem(input: string) {
      if (this.partialItemsList && this.itemsList) {
        this.partialItemsList = this.itemsList.filter(x => 
            (x.name.toLowerCase().includes(input.toLowerCase())) ||
            (x.description.toLowerCase().includes(input.toLowerCase())));
      }
    }
}
