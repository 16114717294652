import { Component, OnInit } from '@angular/core';
import { AdministrationService, Customer } from "process-smart-ng6-lib";
import { SharedService } from "src/app/_services/shared.service";
import { CustomDownloadServiceService } from "src/app/_services/custom-download-service.service";
import { ActivatedRoute } from "@angular/router";
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-view-customers',
  templateUrl: './view-customers.component.html',
  styleUrls: ['./view-customers.component.css']
})
export class ViewCustomersComponent implements OnInit {
    itemsList: Array<Customer>;
    partialItemsList: Array<Customer>;
    deptId: string;
    is_downloading_list: boolean;

    constructor(private route: ActivatedRoute, private adms: AdministrationService, private ssv: SharedService,
            private cds: CustomDownloadServiceService) { }
    
    ngOnInit() {
        this.route.params.subscribe(params => {
            this.deptId = params['deptId'];
            this.populateItems();
        });
        
        this.is_downloading_list = false;
        
        //Date
        const date = new Date();
        const dd = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
        const mm = date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
        const yyyy = date.getFullYear();
        
        const uid = JSON.parse(localStorage.getItem('currentUser')).user.uid;
    }
    
    populateItems() {
    this.adms.getCustomersByDept(this.deptId)
      .subscribe(itemsList => {
          //Sort by name (ascending)
          if(itemsList){
              this.itemsList = itemsList.sort((a, b) => {
                  return (a.project_name > b.project_name ? 1 : a.project_name < b.project_name ? -1 : 0);
              });
          }
          this.partialItemsList = itemsList;
      });
    }
    
    searchItem(input: string) {
      if (this.partialItemsList && this.itemsList) {
        this.partialItemsList = this.itemsList.filter(x => 
            (!x.jobnum || !x.project_name ||
            x.jobnum.toLowerCase().includes(input.toLowerCase())) ||
            x.project_name.toLowerCase().includes(input.toLowerCase()));
      }
    }
    
    downloadAll(){
        this.is_downloading_list = true;
        this.cds.downloadCustomerListXls()
        .subscribe(
            res => {
                saveAs( res, 'CustomerList.xls', 
                    { type: 'application/vnd.ms-excel' });
                this.is_downloading_list = false;
            },
            error => {
                // Display error message
                console.log(error);
                this.ssv.showError('Error: Unable to download list!');
                this.is_downloading_list = false;
            }
        );
    }
}
