import { Component, OnInit } from '@angular/core';
import { User } from 'process-smart-ng6-lib';
import { AuthenticationService } from 'process-smart-ng6-lib';

@Component({
  selector: 'app-dashboard-topbar',
  templateUrl: './dashboard-topbar.component.html',
  styleUrls: ['./dashboard-topbar.component.css']
})
export class DashboardTopbarComponent implements OnInit {

    user: any;

  constructor(private auts: AuthenticationService) {
  }

  ngOnInit() {
      this.user = JSON.parse(localStorage.getItem('currentUser')).user;
      this.setRole(this.user.role_id);
  }

  setRole(rid: string) {
    this.auts.getRole(rid)
        .subscribe(role => this.user.role = role.title);
  }

  onRefresh(){
      var userAgent = window.navigator.userAgent.toLowerCase(),
      safari = /safari/.test( userAgent ),
      ios = /iphone|ipod|ipad/.test( userAgent );
      
      if( ios ) {
          window.location.reload();
      } else {
       // @ts-ignore
          Android.reloadWebview();
      };
  }
}
