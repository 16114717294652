import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { AuthenticationService, Role, User } from "process-smart-ng6-lib";
import { SharedService } from "src/app/_services/shared.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-form-employees',
  templateUrl: './form-employees.component.html',
  styleUrls: ['./form-employees.component.css']
})
export class FormEmployeesComponent implements OnInit {
    model: User;
    model_id: string;
    roleId: string;
    rolesList: Array<Role>;
    user_password: string;
    langList: Array<any>;
    
    constructor(private adms: AuthenticationService, private ssv: SharedService, 
            private router: Router, private route: ActivatedRoute) { }
    
    ngOnInit() {
        //Language list
        this.langList = [];
        this.langList.push({abbr:'en', title:'English'});
        this.langList.push({abbr:'es', title:'Spanish'});
        
        //Authentication
        this.roleId = JSON.parse(localStorage.getItem('currentUser')).user.role_id;
        const uid = JSON.parse(localStorage.getItem('currentUser')).user.uid;

        this.route.params.subscribe(params => {
            this.model_id = params['id'];
            if(this.model_id){
                //Existing profile - User must be Admin or their own profile
                if(this.roleId != this.ssv.admin_role_id && this.model_id != uid){
                    this.adms.logout();
                    this.router.navigate(['/login']);
                }
                
                this.loadModel();
            }
            else{
                //New profile - User must be Admin
                if(this.roleId != this.ssv.admin_role_id){
                    this.adms.logout();
                    this.router.navigate(['/login']);
                }
                
                this.model = <User>{
                    'id': '0',
                    'role_id': '0',
                    'language_preference': 'en'
                };
            }
        });
        
        this.populateRoles();
    }
    
    populateRoles() {
        this.adms.getRoles()
          .subscribe(itemsList => {
              this.rolesList = itemsList;
          });
        }
    
    loadModel() {
        this.adms.getUser(this.model_id)
            .subscribe(model => {
                model.password = "";
                this.model = model;
        });
    }
    
    onSubmit() {
        //Submit the form to the server
        console.log(JSON.stringify(this.model));
          if(this.model_id){
              this.adms.updateUser(this.model, this.model_id)
              .subscribe(
                  data => {
                      // Page redirect when getting response
                      this.ssv.showSuccess('Profile successfully updated!');
                      this.router.navigate(['/form-employee/'+this.model_id]);
                  },
                  error => {
                      // Display error message
                      console.log(error);
                      this.ssv.showError('Error: Username is taken');
                  }
              );
          }
          else{
              this.adms.newUser(this.model)
                .subscribe(
                    data => {
                        // Page redirect when getting response
                        this.ssv.showSuccess('User successfully created!');
                        this.router.navigate(['/view-employees']);
                    },
                    error => {
                        // Display error message
                        console.log(error);
                        this.ssv.showError('Error: Username has been taken!');
                    }
                );
          }
      }
    
    onDeactivate() {
          this.adms.deactivateUser(this.model_id)
          .subscribe(
              data => {
                  // Page redirect when getting response
                  this.ssv.showSuccess('Account successfully deactivated!');
                  this.router.navigate(['/view-employees']);
              },
              error => {
                  // Display error message
                  console.log(error);
                  this.ssv.showError('Error: Unable to deactivate account!');
              }
          );
      }
    
    onReactivate() {
        this.adms.reactivateUser(this.model_id)
        .subscribe(
            data => {
                // Page redirect when getting response
                this.ssv.showSuccess('Account successfully reactivated!');
                this.router.navigate(['/view-employees']);
            },
            error => {
                // Display error message
                console.log(error);
                this.ssv.showError('Error: Unable to reactivate account!');
            }
        );
    }

    getRole(roleId: string) {
        if (this.rolesList) {
            const role = this.rolesList.find(x => x.id == roleId);
            if(role){
                return role.title;
            }
        }
        else return "-";
      }
}
