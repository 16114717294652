import { Component, OnInit } from '@angular/core';
import { Form, User } from "process-smart-ng6-lib";
import { FormManagementService, DocumentConversionService, AuthenticationService } from "process-smart-ng6-lib";
import { SharedService } from "src/app/_services/shared.service";
import { saveAs } from 'file-saver';
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-view-cost-summary',
  templateUrl: './view-cost-summary.component.html',
  styleUrls: ['./view-cost-summary.component.css']
})
export class ViewCostSummaryComponent implements OnInit {
    itemsList: Array<Form>;
    partialItemsList: Array<Form>;
    usersList: Array<User>;
    formId = '50';
    processNum: string;
    
    constructor(private fms: FormManagementService, private adms: AuthenticationService,
          private dcs: DocumentConversionService, private ssv: SharedService,
          private route: ActivatedRoute) { }
    
    ngOnInit() {
        this.populateNames();
        
        this.route.params.subscribe(params => {
            this.processNum = params['processNum'];
            this.populateItems();
        });
        
        const uid = JSON.parse(localStorage.getItem('currentUser')).user.uid;
    }
    
    populateItems() {
    this.fms.getForms(this.formId, 20, 'DESC', 0)
      .subscribe(itemsList => {
          if(this.processNum){
              itemsList = itemsList.filter(x => x.process_number == this.processNum);
          }
          
          if(itemsList){
              this.itemsList = itemsList.sort((a, b) => {
                  return (a.id < b.id ? 1 : a.id > b.id ? -1 : 0);
              });
          }
          this.partialItemsList = itemsList;
      });
    }
    
    populateNames() {
      this.adms.getUsers()
        .subscribe(usersList => this.usersList = usersList);
    }
    
    getEmployeeName(uid: string) {
        if (uid == null || this.usersList == null) { return ''; }
        const user = this.usersList.find(x => x.uid === uid);
        return (user? user.name : '-');
    }
    
    onDownload(id: number) {
    //  this.dcs.DownloadWorkOrder(id)
    //      .subscribe(
    //          res => {
    //              saveAs(res, 'WorkOrder.xls', 
    //                  { type: 'application/vnd.ms-excel' })
    //          },
    //          error => {
    //              // Display error message
    //              this.ssv.showError('Error: Unable to download form!');
    //          }
    //      );
    }
    
    searchItem(input: string) {
      if (this.partialItemsList && this.itemsList) {
        this.partialItemsList = this.itemsList.filter(x => 
            (x.inputMap.job_num.toLowerCase().includes(input.toLowerCase())) ||
            x.inputMap.project_name.toLowerCase().includes(input.toLowerCase()));
      }
    }
}
